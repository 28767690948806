import React, { useState, ChangeEvent } from 'react';
import TextInput from 'components/TextInput';
import { InputAdornment } from '@material-ui/core';
import { AiOutlineSearch } from 'react-icons/ai';
import { searchUser } from 'API/app/user';
import { User } from 'types/User';
import MenuItem from 'components/ContextMenu/components/MenuItem';
import ProfilePicture from 'components/ProfilePicture';
import { useHistory } from 'react-router-dom';
import { setTimeout } from 'timers';

const inputRef = React.createRef<HTMLInputElement>();

function Search() {
  const [users, setUsers] = useState<Array<User>>([]);
  const [showResult, setShowResult] = useState<boolean>(false);
  const history = useHistory();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    searchUser(e.target.value)
      .then(data => {
        if (data.success) {
          setUsers(data.users);
          setShowResult(true);
        }
      })
      .catch(err => console.error(err));
  }

  const handleGoToPage = (userName?: string) => {
    history.push(`/${userName}`);
    history.go(1);

    setShowResult(false);

    if (inputRef.current) {
      inputRef.current.value = '';
    }

  }

  const handleInputFocusLost = () => {
    setTimeout(() => setShowResult(false), 300);
  }

  return (
    <div className='search-input-container'>
      <TextInput
        name='search'
        type='search'
        label='Rechercher'
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <AiOutlineSearch size={20} />
            </InputAdornment>
          )
        }}
        inputProps={{
          style: {
            fontSize: '14px',
          },
          onBlur: handleInputFocusLost
        }}
        onChange={handleChange}
      />
      <div className='search-result-container'>
        <div className='search-result-content'>
          {
            showResult &&
            users.map((user, idx) => (
              <MenuItem
                text={user.name}
                icon={<ProfilePicture src={user.profilePicture} />}
                key={idx}
                onClick={() => handleGoToPage(user.name)}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default Search;
