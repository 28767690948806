import './body.css'
import React, { Component } from 'react';
import MessageItem from './MessageItem';
import { TchatMessage } from 'types/TchatMessage';
import { authContext } from 'components/Providers/AuthProvider';

type Props = {
  messages?: TchatMessage[],
  onMessageLiked: (msgId?: string) => void,
  onRemoveMessage: (msgId?: string) => void
};

type State = {};

class Body extends Component<Props, State> {
  static contextType = authContext;
  endDivRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.endDivRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  render() {
    return (
      <div className='tchat-body-container'>
        {
          this.props.messages?.map((item: TchatMessage, inx: number) => {
            let isLastMsgOfUser = true;
            if (this.props.messages && inx + 1 < this.props.messages.length) {
              const nextItem = this.props.messages[inx + 1];
              isLastMsgOfUser = nextItem.author?._id !== item.author?._id;
            }

            let showAuthor = true;
            if (this.props.messages && inx - 1 >= 0) {
              const prevItem = this.props.messages[inx - 1];
              showAuthor = prevItem.author?._id !== item.author?._id;
            }

            return (
              <MessageItem
                item={item}
                localUserId={this.context.user._id}
                showProfilePicture={isLastMsgOfUser}
                showAuthor={showAuthor}
                key={inx}
                onMessageLiked={this.props.onMessageLiked}
                onRemoveMessage={this.props.onRemoveMessage}
              />
            );
          })
        }
        <div ref={this.endDivRef} />
      </div>
    );
  }
}

export default Body;