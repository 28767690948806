import './profilePage.css';
import defaultProfilePicture from 'assets/images/default_profile_picture.png';
import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { User } from 'types/User';
import { getUser, subscribe } from 'API/app/user';
import { authContext } from 'components/Providers/AuthProvider';
import Button from 'components/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/TabPanel';
import { BsBookmark, BsGrid3X3 } from 'react-icons/bs';
import { withStyles } from '@material-ui/core';
import { FaUserCheck } from 'react-icons/fa';
import PostList from 'components/PostList';
import { popupRef } from 'components/Popup';
import Page404 from 'scenes/Page404';

const StyledTab = withStyles((theme) => ({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
}))((props: any) => <Tab {...props} />)

function ProfilePage() {
  const [user, setUser] = useState<User>();
  const [isOwnPage, setIsOwnPage] = useState(false);
  const [subscibed, setSubscribed] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [loaded, setLoaded] = useState(false)

  const location = useLocation();
  const history = useHistory();
  const isDesktopDevice = useMediaQuery({ query: '(min-width: 735px)' });

  const auth = useContext(authContext);

  const tabIconSize = isDesktopDevice ? 16 : 30;

  useEffect(() => {
    const historyState: any = location.state as any;

    if (historyState?.tabIndex) {
      setTabValue(historyState.tabIndex);
    }
  }, [user])

  useEffect(() => {
    const username = location.pathname.replace('/', '');
    if (username) {
      getUser(username)
        .then((userObj: User) => {
          setLoaded(true);
          if (userObj._id) {
            setUser(userObj);
            setIsOwnPage(userObj._id === auth.user._id);
            setSubscribed(auth.user.subscriptions?.includes(userObj._id));
          }
        })
    }
  }, [location]);

  const handleProfilePhotoError = (e: any) => {
    e.target.src = defaultProfilePicture;
  }

  const handleEditProfile = () => {
    history.push('/account/settings');
    history.go(1);
  }

  const handleTabChange = (event: ChangeEvent<{}>, newValue: any) => {
    setTabValue(newValue);
  };

  const handleSubscribe = () => {
    subscribe(user?.name)
      .then(data => {
        let subscrabers = user?.subscrabers ? user.subscrabers : [];
        if (data.subscribed) {
          subscrabers.push(auth.user._id);
        }
        else {
          subscrabers = subscrabers.filter(f => f != auth.user._id);
        }
        setUser(user => Object.assign(user, { subscrabers }));
        setSubscribed(data.subscribed);
      })
      .catch(err => console.error(err));
  }

  const handleUnsubscribe = () => {
    popupRef.current?.open(`Se désabonner de ${user?.name} ?`, `Êtes-vous sûr de vouloir vous désabonner de ${user?.name} ?`, handleSubscribe);
  }

  const handleContact = () => {
    history.push('/messaging', { selectGroup: { with: user?._id } });
  }

  const getInfo = () => {
    return (
      <div className='info-content'>
        <li>
          <ul><b>{user?.posts?.length}</b> publications</ul>
          <ul><b>{user?.subscrabers?.length}</b> abonnés</ul>
          <ul><b>{user?.subscriptions?.length}</b> abonnements</ul>
        </li>
        <p className='pp-profile-bio'>{user?.description}</p>
      </div>
    );
  }

  const getInfoButtons = () => {
    if (isOwnPage) {
      return <Button color='secondary' fullWidth={false} onClick={handleEditProfile}>Modifier profil</Button>
    }
    else if (subscibed) {
      return (
        <>
          <Button color='secondary' fullWidth={false} onClick={handleContact}>Contacter</Button>
          <Button color='secondary' fullWidth={false} onClick={handleUnsubscribe}>
            <FaUserCheck />
          </Button>
        </>
      )
    }
    else {
      return <Button fullWidth={false} onClick={handleSubscribe}>S'abonner</Button>
    }
  }

  if (!user && loaded) {
    return (
      <Page404 />
    );
  }

  return (
    <div className='pp-container'>
      <div className='pp-main'>
        <div className="pp-infos">
          <div>
            <img
              className='pp-photo'
              src={user?.profilePicture ? user?.profilePicture : defaultProfilePicture}
              alt='profile avatar'
              onError={handleProfilePhotoError}
            />
          </div>
          <div>
            <div className='pp-infos-group-container'>
              <h1 className='pp-info-name'>{user?.name}</h1>
              <div className='pp-info-buttons'>
                {getInfoButtons()}
              </div>
            </div>
            {isDesktopDevice && getInfo()}
          </div>
        </div>
        {!isDesktopDevice && getInfo()}
        <div className='pp-tabs-container'>
          <Tabs
            value={tabValue}
            centered
            TabIndicatorProps={{
              style: {
                backgroundColor: 'rgba(var(--contrast), 1)',
                top: '-1px',
                bottom: 'auto',
                display: isDesktopDevice ? 'block' : 'none'
              }
            }}
            onChange={handleTabChange}
          >
            <StyledTab
              label={isDesktopDevice && 'Publications'}
              icon={<BsGrid3X3 size={tabIconSize} color={(!isDesktopDevice && tabValue === 0) ? 'var(--primary)' : ''} />}
            />
            {
              isOwnPage && (
                <StyledTab
                  label={isDesktopDevice && 'Enregistrements'}
                  icon={<BsBookmark size={tabIconSize * 1.3} color={(!isDesktopDevice && tabValue === 1) ? 'var(--primary)' : ''} />}
                />
              )
            }
          </Tabs>
        </div>
        <TabPanel value={tabValue} index={0}>
          <PostList postsIds={user?.posts} />
        </TabPanel>
        {
          isOwnPage && (
            <TabPanel value={tabValue} index={1}>
              <PostList postsIds={user?.savedPosts} />
            </TabPanel>
          )
        }
      </div>
    </div>
  );
}

export default ProfilePage;
