import axios from "axios";
import { getConfig } from "./axiosConfig";

export async function getGroups() {
  const response = await axios.post('/tchat/getgroups', {}, getConfig());
  return response.data;
}

export async function getOneGroup(groupId?: string) {
  const response = await axios.post('/tchat/getonegroup', { groupId }, getConfig());
  return response.data;
}

export async function createGroup(socketId: string, membersIds: (string | undefined)[]) {
  const response = await axios.post('/tchat/createGroup', { socketId, membersIds }, getConfig());
  return response.data;
}

export async function editGroupSettings(socketId: string, groupId: string, settings: any) {
  const response = await axios.post('/tchat/editgroupsettings', {
    socketId,
    groupId,
    ...settings
  }, getConfig());

  return response.data;
}

export async function leaveGroup(socketId: string, groupId: string, memberIdToExclude: string) {
  const response = await axios.post('/tchat/leavegroup', {
    socketId,
    groupId,
    memberIdToExclude
  }, getConfig());

  return response.data;
}

export async function addUsersToGroup(socketId: string, groupId: string, usersIdsToInclude: string[]) {
  const response = await axios.post('/tchat/newmember', {
    socketId,
    groupId,
    usersIdsToInclude
  }, getConfig());

  return response.data;
}

export async function deleteGroup(socketId: string, groupId: string) {
  const response = await axios.post('/tchat/deletegroup', { socketId, groupId }, getConfig());
  return response.data;
}

export async function sendMessage(socketId: string, groupId?: string, message?: string) {
  const response = await axios.post('/tchat/send', { socketId, groupId, message }, getConfig());
  return response.data;
}

export async function removeMessage(socketId: string, groupId?: string, msgId?: string) {
  const response = await axios.post('/tchat/removemsg', { socketId, groupId, msgId }, getConfig());
  return response.data;
}

export async function likeMessage(socketId: string, groupId?: string, msgId?: string) {
  const response = await axios.post('/tchat/likemsg', { socketId, groupId, msgId }, getConfig());
  return response.data;
}