import React from 'react';
import { ChangeEventHandler } from 'react';
import TextField from '@material-ui/core/TextField';

type TextInputProps = {
    name: string,
    type: string,
    label: string,
    required?: boolean,
    multiline?: boolean,
    defaultValue?: string,
    variant?: 'outlined' | 'filled' | 'standard'
    rows?: number,
    fullWidth?: boolean,
    InputProps?: any,
    inputProps?: any,
    inputRef?: React.RefObject<HTMLInputElement>
    onChange?: ChangeEventHandler
};

function TextInput({ name, type, label, required, onChange, variant = 'outlined', ...rest }: TextInputProps) {
    return (
        <TextField
            name={name}
            type={type}
            label={label}
            required={required}
            variant={variant}
            autoComplete='off'
            size='small'
            inputProps={{
                style: {
                    fontSize: '14px',
                }
            }}
            fullWidth={true}
            margin='dense'
            onChange={onChange}
            {...rest}
        />
    )
}

export default TextInput;
