export function urlParamsToObj(search: string) {
    const params: any = {};
    if (search) {
        const paramsString: String = search.replace('?', '');
        const paramsArray: Array<String> = paramsString.split('&');

        paramsArray.forEach(p => {
            const dic = p.split('=');
            Object.assign(params, { [dic[0]]: dic[1] });
        });
    }
    return params;
}