import ProfilePicture from 'components/ProfilePicture';
import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';


function UsernameTitle({ user, description = '', className = '', nameStyle = {}, imgSize = 30, goToPageOnClick = false, menu = <></> }) {
  const history = useHistory();

  const handleGoToPage = () => {
    if (goToPageOnClick) {
      history.push(`/${user?.name}`);
      history.go(1);
    }
  }

  const styledMenu = () => {
    if (React.isValidElement(menu)) {
      return React.cloneElement(menu, {
        style: {
          position: 'absolute',
          right: 10,
          top: '50%',
          transform: 'translateY(-50%)',
        }
      });
    }
    else {
      return menu;
    }
  }

  return (
    <div
      className={className}
      style={{
        position: 'relative',
      }}
    >
      {styledMenu()}
      <div
        onClick={handleGoToPage}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: goToPageOnClick ? 'pointer' : 'default',
        }}
      >
        <ProfilePicture src={user?.profilePicture} size={imgSize} />
        <div style={{ margin: 0 }}>
          <h3 style={nameStyle ? nameStyle : { margin: 0, fontWeight: 600 }}>{user?.name}</h3>
          {
            description && (
              <p
                style={{
                  margin: 0,
                  fontSize: 14,
                  color: 'rgb(142, 142, 142)'
                }}
              >
                {description}
              </p>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default UsernameTitle;
