import React, { Component } from 'react';
import PropTypes from 'prop-types';

import svgImg from 'assets/images/loading-spin.svg';
import completeImg from 'assets/images/check_icon.svg';

export default class LoadingIndicator extends Component {
    static propTypes = {
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        loadingCompleted: PropTypes.bool
    };

    static defaultProps = {
        height: 64,
        width: 64,
        loadingCompleted: false
    };

    render() {
        const {
            height, width, loadingCompleted
        } = this.props;

        const style = {
            height,
            width,
        };

        return (
            <img
                style={style}
                src={loadingCompleted ? completeImg : svgImg}
                alt='loading...'
            />
        );
    }
}
