import './upload.css';
import add_media_img from 'assets/images/add_media.png';
import React, { ChangeEvent } from 'react';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from 'components/Button';
import DragAndDrop from 'components/DragAndDrop';
import PostForm from './PostForm';
import { AiOutlineClose } from 'react-icons/ai';

type Props = {
    ref?: React.RefObject<Upload>
}

type State = {
    open: boolean,
    medias: any
}

export const uploadRef = React.createRef<Upload>();

class Upload extends React.Component<Props, State> {
    inputRef = React.createRef<HTMLInputElement>();

    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
            medias: []
        }

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.handleSelectFromMachine = this.handleSelectFromMachine.bind(this);
        this.handleFileChoosen = this.handleFileChoosen.bind(this);
        this.addFiles = this.addFiles.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
    }

    open() {
        this.setState({ open: true, medias: [] });
    }

    close() {
        this.setState({ open: false, medias: [] });
    }

    handleSelectFromMachine() {
        this.inputRef.current?.click();
    }

    addFiles(files: FileList | null) {
        let newFiles = [];

        let filesLength = 0;
        if (files) {
            filesLength = files.length;
        }
        else {
            return;
        }

        for (let i = 0; i < filesLength; i++) {
            const file = files[i];
            if (!file?.type.includes('image') && !file?.type.includes('video')) {
                continue;
            }
            const exists = this.state.medias.find((f: File) => f.name === file?.name);
            if (!exists) {
                newFiles.push(file);
            }
        }

        this.setState({
            medias: [...this.state.medias, ...newFiles]
        });
    }

    handleFileChoosen(e: ChangeEvent<HTMLInputElement>) {
        this.addFiles(e.target.files)
    }

    handleDrop(files: FileList) {
        this.addFiles(files);
    }

    handleRemoveImage(file: File) {
        this.setState({
            medias: this.state.medias.filter((m: File) => m.name !== file.name)
        });
    }

    render() {
        return (
            <Modal
                className='upload-modal'
                open={this.state.open}
                onClose={this.close}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={this.state.open}>
                    <div className='upload-container'>
                        <div className='upload-close' onClick={this.close}>
                            <AiOutlineClose size={25} />
                        </div>
                        <h1 className='upload-title'>Nouvelle publication</h1>
                        <DragAndDrop className='upload-content' handleDrop={this.handleDrop} >
                            <input
                                type='file'
                                ref={this.inputRef}
                                style={{ display: 'none' }}
                                multiple
                                accept='image/*, video/*'
                                onChange={this.handleFileChoosen}
                            />
                            {
                                this.state.medias.length == 0 ? (
                                    <>
                                        <img src={add_media_img} alt='media' />
                                        <p className='upload-drop-text'>Faites glisser les photos et les vidéos ici.</p>
                                        <Button fullWidth={false} onClick={this.handleSelectFromMachine}>
                                            Sélectionner sur mon appareil
                                        </Button>
                                    </>
                                ) :
                                    <PostForm
                                        medias={this.state.medias}
                                        onAddImage={this.handleSelectFromMachine}
                                        onRemoveImage={this.handleRemoveImage}
                                    />
                            }
                        </DragAndDrop>
                    </div>
                </Fade>
            </Modal>
        );
    }
}

export default Upload;