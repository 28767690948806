import './profileMenu.css';
import React, { useContext } from 'react';
import MenuItem from 'components/ContextMenu/components/MenuItem';
import { CgProfile } from 'react-icons/cg';
import { BsBookmark } from 'react-icons/bs';
import { AiOutlineSetting } from 'react-icons/ai'
import { IoIosLogOut } from 'react-icons/io';
import { authContext } from 'components/Providers/AuthProvider';
import { useHistory } from 'react-router-dom';

const iconConfig = {
    size: 20,
    strokeWidth: 0
}

function ProfileMenu() {
    const { user, logout } = useContext(authContext);
    const history = useHistory();

    const handleNavigate = (page: string, state?: any) => {
        history.push(page, state);
        history.go(1);
    }

    return (
        <div className='pm-container'>
            <div className='pm-pointer'></div>
            <div className='pm-items-container'>
                <MenuItem
                    text='Profile'
                    icon={<CgProfile {...iconConfig} />}
                    onClick={() => handleNavigate(`/${user.name}`)}
                />
                <MenuItem
                    text='Enregistrés'
                    icon={<BsBookmark {...iconConfig} />}
                    onClick={() => handleNavigate(`/${user.name}`, { tabIndex: 1 })}
                />
                <MenuItem
                    text='Paramètres'
                    icon={<AiOutlineSetting {...iconConfig} />}
                    onClick={() => handleNavigate('/account/settings')}
                />
                <hr />
                <MenuItem
                    text='Déconnexion'
                    icon={<IoIosLogOut {...iconConfig} />}
                    onClick={logout}
                />
            </div>
        </div>
    );
}

export default ProfileMenu;
