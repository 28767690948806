import React, { useState, useContext, ChangeEvent } from 'react';
import TextInput from 'components/TextInput';
import ProfilePicture from 'components/ProfilePicture';
import { authContext } from 'components/Providers/AuthProvider';
import Button from 'components/Button';
import { changePassword, sendResetPasswordLink } from 'API/app/auth';
import { popupRef } from 'components/Popup';
import ToastAlert from 'components/ToastAlert';

function ChangePassword() {
  const { user } = useContext(authContext);
  const [fields, setFields] = useState<any>({});
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<string>>([]);

  const closeAlert = () => {
    setOpenAlert(false);
  }

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFields((fields: any) => ({
      ...fields,
      [e.target.name]: e.target.value
    }));
  }

  const handlePasswordLost = () => {
    sendResetPasswordLink(user.name)
      .then(data => {
        if (data.success) {
          popupRef.current?.open('Réinitialiser votre mot de passe', `Un lien de réinitialisation de votre mot de passe été envoyé à ${user.email}.`);
        }
      })
      .catch(err => console.error(err));
  }

  const handleSubmit = () => {
    changePassword(fields)
      .then(data => {
        if (data.success) {
          setErrors([]);
          setOpenAlert(true);
        }
        else if (data.errors) {
          setErrors(data.errors);
        };
      })
      .catch(err => console.error(err));
  }

  return (
    <div className='sp-tab-content'>
      <ToastAlert open={openAlert} onClose={closeAlert}>
        Votre mot de passe a été changé avec succès.
      </ToastAlert>
      <div className='sp-row'>
        <div>
          <ProfilePicture src={user.profilePicture} size={40} />
        </div>
        <div>
          <p className='sp-username'>{user.name}</p>
        </div>
      </div>

      <div className='sp-row'>
        <div>
          <h4>Ancien mot de passe</h4>
        </div>
        <div>
          <TextInput
            name='oldPsw'
            type='password'
            label=''
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className='sp-row'>
        <div>
          <h4>Nouveau mot de passe</h4>
        </div>
        <div>
          <TextInput
            name='psw'
            type='password'
            label=''
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className='sp-row'>
        <div>
          <h4>Confirmez le nouveau mot de passe</h4>
        </div>
        <div>
          <TextInput
            name='pswConfirm'
            type='password'
            label=''
            onChange={handleFieldChange}
          />
        </div>
      </div>

      {
        errors?.length > 0 && (
          <div className="sp-row">
            <div />
            <div>
              <p className='error-text'>{errors.join('\n')}</p>
            </div>
          </div>
        )
      }

      <div className='sp-row'>
        <div />
        <div>
          <Button onClick={handleSubmit}>Modifier le mot de passe</Button>
          <p className='text-btn' onClick={handlePasswordLost}>
            Mot de passe oublié ?
          </p>
        </div>
      </div>

    </div>
  );
}

export default ChangePassword;