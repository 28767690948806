import Navigation from 'components/Navigation';
import { Popup, popupRef } from 'components/Popup';
import ComingSoon, { comingSoonRef } from 'components/ComingSoon';
import AuthProvider from 'components/Providers/AuthProvider';
import SocketProvider from 'components/Providers/SocketProvider';

function App() {
  return (
    <AuthProvider>
      <SocketProvider>
        <Navigation />
        <Popup ref={popupRef} />
        <ComingSoon ref={comingSoonRef} />
      </SocketProvider>
    </AuthProvider>
  );
}

export default App;
