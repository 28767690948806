import React, { ChangeEvent, FormEvent, useState } from 'react';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { sendResetPasswordLink } from 'API/app/auth';

type Props = {
    onResetRequestSent: Function
}

function PasswordLostForm({ onResetRequestSent }: Props) {
    const [login, setLogin] = useState('');
    const [errors, setErrors] = useState([]);

    const handleLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLogin(e.target.value);
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (login) {
            sendResetPasswordLink(login)
                .then(data => {
                    if (data.success) {
                        onResetRequestSent();
                    }
                    else if (data.errors?.length > 0) {
                        setErrors(data.errors);
                    }
                });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <TextInput
                name='login'
                type='text'
                label="Nom d'utilisateur ou email"
                required={true}
                onChange={handleLoginChange}
            />
            <Button color='primary'>Réinitialiser mon mot de passe</Button>
            {
                errors?.length > 0 && <p className='error-text'>{errors.join('\n')}</p>
            }
        </form>
    )
}

export default PasswordLostForm;
