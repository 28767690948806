import './groupDetails.css';
import React, { useContext } from 'react';
import { TchatGroup } from 'types/TchatGroup';
import UsernameTitle from 'components/UsernameTitle';
import { authContext } from 'components/Providers/AuthProvider';
import { popupRef } from 'components/Popup';
import { deleteGroup } from 'API/app/tchat';
import ContextMenu from 'components/ContextMenu';
import { AiOutlineMore } from 'react-icons/ai';
import MenuItem from 'components/ContextMenu/components/MenuItem';

type Props = {
  group: TchatGroup,
  onFinish: () => void,
  onSettingsChange: (groupName: string) => void,
  onDeleteGroup: (groupId?: string) => void,
  onLeaveGroup: (groupId?: string, userId?: string) => void,
  onAddUserToGroup: () => void,
}

function GroupDetails({ group, onFinish, onSettingsChange, onDeleteGroup, onLeaveGroup, onAddUserToGroup }: Props) {
  const { user } = useContext(authContext);

  const isAdmin = group?.admin === user._id;
  const gNameInputRef = React.createRef<HTMLInputElement>();

  const handleFinish = () => {
    const newGname = gNameInputRef.current?.value;
    if (isAdmin && newGname && newGname !== group.name) {
      onSettingsChange(newGname);
    }
    onFinish();
  }

  const handleLeaveGroup = () => {
    const execLeaveGroup = () => {
      onLeaveGroup(group._id, user._id);
    }

    popupRef.current?.open('Quitter la discussion ?',
      'Vous ne recevrez aucun message de ce groupe, sauf si quelqu’un vous réintègre dans la discussion.',
      execLeaveGroup)
  }

  const excludeUser = (userId?: string) => {
    if (isAdmin) {
      onLeaveGroup(group._id, userId)

    }
  }

  const handleDelete = () => {
    const execDelete = () => {
      onDeleteGroup(group._id);
    }

    popupRef.current?.open('Terminer la discussion difinitivement ?',
      "Tous les membres du groupe seront retirés de cette discussion.",
      execDelete);
  }

  return (
    <div className='gd-container'>
      <div className='gd-header'>
        <p className='gd-title'>Détails</p>
        <div className='text-btn gd-save-btn' onClick={handleFinish}>Terminer</div>
      </div>
      <div className='gd-group-name-container'>
        <span>Nom du groupe : </span>
        <input
          ref={gNameInputRef}
          className='text-input'
          type='text'
          defaultValue={group?.name}
          placeholder='Saisissez le nom du groupe...'
          disabled={!isAdmin}
        />
      </div>
      <div className='gd-members-container'>
        <div className='gd-members-header'>
          <p className='gd-title'>Membres</p>
          <div className='text-btn' onClick={onAddUserToGroup}>Ajouter des personnes</div>
        </div>
        {
          group?.members?.map((item, index) => (
            <UsernameTitle
              className='gd-member-item'
              user={item}
              description={group.admin == item._id ? 'Administrateur' : ''}
              goToPageOnClick
              key={index}
              nameStyle={{
                margin: 0,
                fontSize: 14
              }}
              imgSize={50}
              menu={
                isAdmin && item._id !== user._id ? (
                  <ContextMenu btnElement={<AiOutlineMore size={20} />}>
                    <MenuItem
                      icon={<></>}
                      text='Exclure du groupe'
                      color='red'
                      onClick={() => excludeUser(item._id)}
                    />
                  </ContextMenu>
                ) : undefined
              }
            />
          ))
        }
      </div>
      <div>
        <div className='text-btn danger-text' onClick={handleLeaveGroup}>Quitter la discussion</div>
        <p className='text-info'>Vous ne recevrez aucun message de ce groupe, sauf si quelqu’un vous réintègre dans la discussion.</p>
        {
          isAdmin && (
            <>
              <div className='text-btn danger-text' onClick={handleDelete}>Terminer la discussion</div>
              <p className='text-info'>Tous les membres du groupe seront retirés de cette discussion.</p>
            </>
          )
        }
      </div>
    </div>
  )
}

export default GroupDetails;
