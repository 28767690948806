import './button.css';
import React, { CSSProperties } from 'react';
import { PaletteColorOptions as Color } from 'types/PaletteColorOptions';

type ButtonProps = {
    children: any,
    size?: number | string,
    className?: string,
    color?: Color,
    fullWidth?: boolean,
    disabled?: boolean,
    isIconBtn?: boolean,
    onClick?: Function
}

function Button({ children, size, className, color = 'primary', fullWidth = true, disabled = false, isIconBtn = false, onClick, ...props }: ButtonProps) {
    const textColor: string = color === 'primary' ? 'white' : 'var(--contrast)';
    let style: CSSProperties = {
        backgroundColor: `var(--${color})`,
        color: textColor
    };

    if (size) {
        Object.assign(style, {
            width: size,
            height: size
        });
    }
    else {
        Object.assign(style, {
            width: 'fit-content',
            height: 'fit-content',
        });
    }

    if (fullWidth && !isIconBtn) {
        Object.assign(style, { width: '100%' });
    }

    if (color === 'secondary' && !isIconBtn) {
        Object.assign(style, {
            border: '1px solid var(--bordercolor)',
        })
    }

    if (isIconBtn) {
        const iconStyle: CSSProperties = {
            backgroundColor: 'transparent',
            padding: '1px',
        }

        Object.assign(style, iconStyle);
    }

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
    }

    return (
        <button
            className={`btn ${className}`}
            style={style}
            disabled={disabled}
            onClick={handleOnClick}
            {...props}
        >
            {children}
        </button>
    )
}

export default Button;