import defaultProfilePicture from 'assets/images/default_profile_picture.png';
import React, { CSSProperties } from 'react';

type Props = {
  src?: string,
  width?: number | string,
  height?: number | string,
  size?: number | string,
  imgStyle?: CSSProperties,
}

function ProfilePicture({ src, width, height, size = 30, imgStyle }: Props) {
  const handleProfilePhotoError = (e: any) => {
    e.target.src = defaultProfilePicture;
  }

  return (
    <img
      style={{
        height: height ? height : size,
        width: width ? width : size,
        borderRadius: '50%',
        marginRight: '5px',
        ...imgStyle
      }}
      src={src ? src : defaultProfilePicture}
      alt='profile avatar'
      onError={handleProfilePhotoError}
    />
  );
}

export default ProfilePicture;
