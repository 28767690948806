import './settingsPage.css';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Tab, Tabs } from '@material-ui/core';
import TabPanel from 'components/TabPanel';
import UserSettings from './UserSettings';
import ChangePassword from './ChangePassword';
import SideMenu from 'components/SideMenu';

function SettingsPage() {
  const [tabValue, setTabValue] = useState(0);
  const isDesktop = useMediaQuery({ query: '(min-width: 760px)' });

  const handleTabChange = (event: React.ChangeEvent<any>, value: any) => {
    setTabValue(value);
  };

  const getTabs = () => {
    return (
      <Tabs
        className='sp-tabs'
        orientation='vertical'
        value={tabValue}
        TabIndicatorProps={{
          style: {
            backgroundColor: 'rgba(var(--contrast), 1)',
            left: 0,
          }
        }}
        onChange={handleTabChange}
      >
        <Tab label='Compte' />
        <Tab label='Changer de mot de passe' />
      </Tabs>
    );
  }

  return (
    <div className='page-container'>
      <div className='sp-content'>
        {
          isDesktop ? getTabs() : (
            <SideMenu position='left'>
              {getTabs()}
            </SideMenu>
          )
        }
        <TabPanel value={tabValue} index={0}>
          <UserSettings />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ChangePassword />
        </TabPanel>
      </div>
    </div>
  );
}

export default SettingsPage;