import React, { useState } from 'react';
import { sendBackActivateLink } from 'API/app/auth';
import Button from 'components/Button';

type Prop = {
    msg: string,
    userId: string,
    error: Boolean,
    showBtn: boolean
}

function NewAccountInfo({ msg, userId, error, showBtn }: Prop) {
    const [localMsg, setLocalMsg] = useState('');

    const handleSendNewToken = () => {
        sendBackActivateLink(userId)
            .then(data => {
                if (data.success) {
                    error = false;
                    setLocalMsg(`Un mail d'activation a été envoyé à ${data.email}.`);
                }
            })
    }

    return (
        <div className='box'>
            <p className={error && !localMsg ? 'error-text' : 'success-text'}>{localMsg ? localMsg : msg}</p>
            {showBtn && <Button color='secondary' onClick={handleSendNewToken}>Renvoyer un mail</Button>}
        </div>
    )
}

export default NewAccountInfo;
