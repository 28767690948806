import { User } from "./User";

export class Comment {
  _id?: string;
  user?: User;
  text?: string;
  created_at?: string;

  constructor(user: User, text: string) {
    this.user = user;
    this.text = text;
  }
}