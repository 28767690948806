import React, { Component } from 'react';
import ToastAlert from 'components/ToastAlert';

type Props = {
  ref: React.RefObject<ComingSoon>
};

type State = {
  alertOpen: boolean
};

export const comingSoonRef = React.createRef<ComingSoon>();

class ComingSoon extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      alertOpen: false
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({ alertOpen: true });
  }

  close() {
    this.setState({ alertOpen: false });
  }

  render() {
    return (
      <ToastAlert open={this.state.alertOpen} alertType='info' onClose={this.close}>
        Fonctionnalité bientôt disponible !
      </ToastAlert>
    )
  }
}

export default ComingSoon;