import React from 'react';
import { TchatGroup } from 'types/TchatGroup';
import GroupIcon from './GroupIcon';

type Props = {
  group: TchatGroup,
  localUsername: string,
  selected: boolean,
  onClick: (group: TchatGroup) => void,
}

function GroupItem({ group, localUsername, selected, onClick }: Props) {
  const groupName = group.name?.split('|').filter(n => n !== localUsername).join('|');

  const handleSelectGroup = () => {
    onClick(group);
  }

  return (
    <div className={`mp-group-item ${selected ? 'mp-group-item-selected' : ''}`} onClick={handleSelectGroup}>
      <GroupIcon group={group} />
      <p>{groupName}</p>
    </div>
  );
}

export default GroupItem;