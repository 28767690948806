import './menuItem.css';
import React from 'react';

type Props = {
    text?: string,
    icon: JSX.Element,
    color?: string
    onClick?: Function,
    toggleOpen?: Function,
}

function MenuItem({ text, icon, color, onClick, toggleOpen }: Props) {
    const handleOnClick = () => {
        if (onClick) {
            if (toggleOpen) {
                toggleOpen();
            }
            onClick();
        }
    }

    return (
        <div
            className='menu-item'
            style={{ color }}
            onClick={handleOnClick}
        >
            {icon}
            <p>{text}</p>
        </div>
    )
}

export default MenuItem;
