import React, { useEffect, useState } from 'react';
import { explore } from 'API/app/post';
import PostList from 'components/PostList';
import { Post } from 'types/Post';
import ListLoadingView from 'components/ListLoadingView';

function ExplorePage() {
  const [posts, setPosts] = useState<Array<Post>>([]);
  const [nextPage, setNextPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [queryDate, setQueryDate] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = () => {
    setIsLoading(true);
    explore(nextPage, 50, queryDate, '$lte')
      .then(data => {
        if (data.success) {
          setPosts(posts => [...posts, ...data.posts]);
          setNextPage(data.page + 1);
          setTotalPage(data.total);
          setQueryDate(data.queryDate);
          setIsLoading(false);
        }
      })
      .catch(error => console.error(error));
  }

  const handleOnScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isOnBottom = scrollTop + clientHeight + 100 >= scrollHeight;

    if (isOnBottom && !isLoading && nextPage <= totalPage) {
      loadPosts();
    }
  }

  return (
    <div className='page-container' onScroll={handleOnScroll}>
      <ListLoadingView isLoading={isLoading} />
      <div>
        <PostList items={posts} />
      </div>
    </div>
  );
}

export default ExplorePage;
