import './tchat.css';
import React, { Component } from 'react';
import Footer from './components/Footer';
import Body from './components/Body';
import GroupIcon from '../GroupIcon';
import { TchatGroup } from 'types/TchatGroup';
import { authContext } from 'components/Providers/AuthProvider';
import { sendMessage, likeMessage, removeMessage } from 'API/app/tchat';
import SocketService from 'services/SocketService';
import { TchatMessage } from 'types/TchatMessage';

type Props = {
  group: TchatGroup
  socket: SocketService,
  addRemoveMessage: (groupId: string, message: TchatMessage, added: boolean) => void,
  onShowDetails: () => void,
  onMsgLiked: (groupId: string, msgId: string, userId: string, isLike: boolean) => void,
};

type State = {

}

class Tchat extends Component<Props, State> {
  static contextType = authContext;

  constructor(props: Props) {
    super(props);

    this.handleSubmitMessage = this.handleSubmitMessage.bind(this);
    this.handleRemoveMessage = this.handleRemoveMessage.bind(this);
    this.onAddDeleteMessage = this.onAddDeleteMessage.bind(this);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
    this.handleLikedMessage = this.handleLikedMessage.bind(this);
    this.onMsgLiked = this.onMsgLiked.bind(this);
  }

  componentDidMount() {
    this.props.socket.on('tchatAddRemoveMessage', this.onAddDeleteMessage);
    this.props.socket.on('msgLiked', this.onMsgLiked);
  }

  componentWillUnmount() {
    this.props.socket.off('tchatAddRemoveMessage', this.onAddDeleteMessage);
    this.props.socket.off('msgLiked', this.onMsgLiked);
  }

  onAddDeleteMessage({ message, groupId, added }: any) {
    if (groupId === this.props.group._id) {
      this.props.addRemoveMessage(groupId, message, added);
    }
  }

  handleSubmitMessage(msg: string) {
    if (msg.length > 0) {
      sendMessage(this.props.socket.socket.id, this.props.group._id, msg)
        .catch(err => console.error(err))
    }
  }

  handleRemoveMessage(msgId?: string) {
    removeMessage(this.props.socket.socket.id, this.props.group._id, msgId)
      .then(data => console.log(data))
  }

  onMsgLiked({ groupId, msgId, userId, isLiked }: any) {
    this.props.onMsgLiked(groupId, msgId, userId, isLiked);
  }

  handleLikedMessage(msgId?: string) {
    likeMessage(this.props.socket.socket.id, this.props.group._id, msgId)
      .catch(err => console.error(err));
  }

  handleHeaderClick() {
    if (this.props.group.members && this.props.group.members?.length > 2) {
      this.props.onShowDetails();
    }
  }

  render() {
    return (
      <div className='tchat-container'>
        <div className='tchat-header' onClick={this.handleHeaderClick}>
          <GroupIcon group={this.props.group} />
          <p>{this.props.group.name?.split('|').filter(n => n !== this.context.user.name).join('|')}</p>
        </div>
        <div className='tchat-body'>
          <Body
            messages={this.props.group.messages}
            onMessageLiked={this.handleLikedMessage}
            onRemoveMessage={this.handleRemoveMessage}
          />
        </div>
        <div className='tchat-footer'>
          <Footer onSubmit={this.handleSubmitMessage} />
        </div>
      </div>
    );
  }
}

export default Tchat;