import './newMessagePopup.css';
import React, { Component, ChangeEvent } from 'react';
import ScreenModal from 'components/ScreenModal';
import SearchItem from './SearchItem';
import { User } from 'types/User';
import RecipientItem from './RecipientItem';
import { searchUser } from 'API/app/user';
import { AiOutlineClose } from 'react-icons/ai';

type Props = {
  ref: React.RefObject<UsersSelectorPopup>,
  user: User,
};

type State = {
  usersSearched: Array<User>,
  recipients: Array<User>,
  suggested: Array<User>,
  excludes: string[],
  onValidate: (membersIds: (string | undefined)[]) => void
}

class UsersSelectorPopup extends Component<Props, State>{
  screenRef = React.createRef<ScreenModal>();

  constructor(props: Props) {
    super(props);

    this.state = {
      usersSearched: [],
      recipients: [],
      suggested: [],
      excludes: [],
      onValidate: (membersIds: (string | undefined)[]) => { }
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.handleSearchRecipientChecked = this.handleSearchRecipientChecked.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleCreateGroup = this.handleCreateGroup.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    searchUser('', user.subscriptions)
      .then((data) => {
        if (data.success) {
          this.setState({
            suggested: data.users.filter((u: User) => !this.state.excludes.includes(u._id ? u._id : '')),
            usersSearched: data.users.filter((u: User) => !this.state.excludes.includes(u._id ? u._id : ''))
          });
        }
      })
      .catch(err => console.error(err));
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.excludes != this.state.excludes) {
      this.setState({
        suggested: this.state.suggested.filter((u: User) => !this.state.excludes.includes(u._id ? u._id : '')),
        usersSearched: this.state.usersSearched.filter((u: User) => !this.state.excludes.includes(u._id ? u._id : '')),
        recipients: []
      });
    }
  }

  open(onValidate: (membersIds: (string | undefined)[]) => void, excludes: string[] = []) {
    this.setState({
      excludes,
      onValidate
    });

    this.screenRef.current?.open();
  }

  close() {
    this.screenRef.current?.close();
  }

  handleSearchRecipientChecked(checked: boolean, user: User) {
    if (checked) {
      this.setState({
        recipients: [...this.state.recipients, user]
      });
    }
    else {
      this.setState({
        recipients: this.state.recipients.filter(r => r._id !== user._id)
      });
    }
  }

  isChecked(user: User) {
    return this.state.recipients.find(r => r._id === user._id) !== undefined;
  }

  handleSearchInputChange(e: ChangeEvent<HTMLInputElement>) {
    searchUser(e.target.value)
      .then(data => {
        if (data.success) {
          if (data.users?.length > 0) {
            this.setState({
              usersSearched: data.users.filter((r: User) => r._id !== this.props.user._id
                && !this.state.excludes.includes(r._id ? r._id : ''))
            });
          }
          else {
            this.setState({
              usersSearched: this.state.suggested
            });
          }
        }
        else {
          this.setState({
            usersSearched: this.state.suggested
          });
        }
      })
      .catch(err => console.error(err));
  }

  handleCreateGroup() {
    const membersIds = this.state.recipients.map((r: User) => r._id);
    this.state.onValidate(membersIds);

    this.close();
  }

  render() {
    return (
      <ScreenModal ref={this.screenRef} showCloseBtn={false} >
        <div className='nmp-container'>
          <div className='nmp-header'>
            <div
              style={{ cursor: 'pointer' }}
              onClick={this.close}
            >
              <AiOutlineClose size={25} />
            </div>
            <p>Nouveau message</p>
            <div
              className={`text-btn ${this.state.recipients.length < 1 ? 'disabled' : ''}`}
              onClick={this.handleCreateGroup}
            >
              Suivant
            </div>
          </div>
          <div className='nmp-search-container'>
            <h4>À :</h4>
            <div className='nmp-recipients-container'>
              {
                this.state.recipients.map((item: User, inx: number) => (
                  <RecipientItem
                    item={item}
                    key={inx}
                    onRemove={this.handleSearchRecipientChecked}
                  />
                ))
              }
              <input
                className='text-input'
                type='text'
                placeholder='Rechercher...'
                onChange={this.handleSearchInputChange}
              />
            </div>
          </div>
          <div className='nmp-search-items-container'>
            {
              this.state.usersSearched.map((item: User, inx: number) => (
                <SearchItem
                  user={item}
                  checked={this.isChecked(item)}
                  key={inx}
                  onChange={this.handleSearchRecipientChecked}
                />
              ))
            }
          </div>
        </div>
      </ScreenModal>
    );
  }
}

export default UsersSelectorPopup;
