export class User {
    _id?: string;
    name?: string;
    signupDate?: string;
    email?: string;
    profilePicture?: string;
    description?: string;
    posts?: Array<string>;
    savedPosts?: Array<string>;
    subscrabers?: Array<string>;
    subscriptions?: Array<string>;
}