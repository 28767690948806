import React from 'react';
import UsernameTitle from 'components/UsernameTitle';
import Checkbox from '@material-ui/core/Checkbox';
import { User } from 'types/User';
import { FaCheckCircle } from 'react-icons/fa';
import { BsCircle } from 'react-icons/bs';

type Props = {
  user: User,
  checked: boolean,
  onChange: (checked: boolean, user: User) => void
};

const iconSize = 25;

function SearchItem({ user, checked, onChange }: Props) {
  const handleChange = (event: any) => {
    onChange(!checked, user);
  }

  return (
    <div className='nmp-search-item' onClick={handleChange}>
      <UsernameTitle
        user={user}
        nameStyle={{
          fontSize: 16,
          fontWeight: 500
        }}
        imgSize={40}
      />
      <Checkbox
        icon={<BsCircle size={iconSize} color='var(--bordercolor)' />}
        checkedIcon={<FaCheckCircle size={iconSize} color='var(--primary)' />}
        checked={checked}
        disableRipple
      />
    </div>
  );
}

export default SearchItem;