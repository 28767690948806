import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PostViewer from 'components/PostViewer';
import { Post } from 'types/Post';
import { getFeed } from 'API/app/user';
import ListLoadingView from 'components/ListLoadingView';
import Suggestions from './Suggestions';

type Props = {}
type States = {
  posts: Array<Post>,
  page: number,
  totalPages: number,
  loading: boolean,
  requestDate?: string,
  mount: boolean
};

class PostFeed extends Component<Props, States> {
  constructor(props: Props) {
    super(props);

    this.state = {
      posts: [],
      page: 0,
      totalPages: Number.MAX_VALUE,
      loading: false,
      mount: false
    };

    this.handleOnScroll = this.handleOnScroll.bind(this);
  }

  componentDidMount() {
    if (ReactDOM) {
      ReactDOM.findDOMNode(this)?.parentNode?.parentNode?.addEventListener('scroll', this.handleOnScroll)
    }

    this.loadPosts();
  }

  componentWillUnmount() {
    if (ReactDOM) {
      ReactDOM.findDOMNode(this)?.parentNode?.parentNode?.removeEventListener('scroll', this.handleOnScroll)
    }
  }

  loadPosts = () => {
    this.setState({ loading: true });
    getFeed(20, this.state.page, this.state.requestDate, '$lt')
      .then(data => {
        if (data.success) {
          this.setState({
            posts: [...data.posts, ...this.state.posts],
            page: data.page + 1,
            totalPages: data.totalPages,
            requestDate: data.requestDate,
            loading: false,
            mount: true
          });
        }
      })
      .catch(err => console.error(err));
  }

  handleOnScroll(e: any) {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isOnBottom = scrollTop + clientHeight + 100 >= scrollHeight;

    if (isOnBottom && !this.state.loading && this.state.page <= this.state.totalPages) {
      this.loadPosts();
    }
  }

  render() {
    return (
      <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
        <ListLoadingView isLoading={this.state.loading} />
        <div>
          {
            this.state.posts.map((post, index) => (
              <PostViewer
                item={post}
                verticalMode
                style={{ marginBottom: 15, border: '1px solid #dbdbdb' }}
                key={index}
              />
            ))
          }
          {this.state.posts.length == 0 && this.state.mount && <Suggestions />}
        </div>
      </div>
    );
  }
}

export default PostFeed;
