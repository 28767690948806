import './mediaList.css';
import React, { useState, useEffect } from 'react';
import { Post } from 'types/Post';
import { getPosts } from 'API/app/post';
import PostListItem from './PostListItem';
import ScreenModal from 'components/ScreenModal';
import PostViewer from 'components/PostViewer';

type Props = {
  postsIds?: Array<string>,
  items?: Array<Post>
}

const postViewerRef = React.createRef<ScreenModal>();

function PostList({ postsIds, items }: Props) {
  const [posts, setPosts] = useState<Array<Post>>([]);
  const [selectedPost, setSelectedPost] = useState<Post>();

  useEffect(() => {
    if (!items) {
      getPosts(postsIds)
        .then(data => {
          if (data.success) {
            setPosts(Object.assign(new Array<Post>(), data.posts));
          }
        })
        .catch(error => console.error(error));

    }
    else {
      setPosts(items);
    }
  }, [postsIds, items]);

  const handlePostItemClick = (post: Post) => {
    setSelectedPost(post);
    postViewerRef.current?.open();
  }

  return (
    <div className='ml-container'>
      {
        posts.map((p, index) => (
          <PostListItem post={p} key={index} onClick={handlePostItemClick} />
        ))
      }
      <ScreenModal ref={postViewerRef}>
        <PostViewer postId={selectedPost?._id} />
      </ScreenModal>
    </div>
  );
}

export default PostList;
