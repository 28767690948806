import React from 'react';
import { Link } from 'react-router-dom'

function Page404() {
  return (
    <div style={{
      textAlign: 'center',
      padding: 20
    }}>
      <h3>Cette page n’est malheureusement pas disponible.</h3>
      <p>Le lien que vous avez suivi est peut-être rompu, ou la page a été supprimée. <Link to='/'>Revenir à Instaclone</Link>.</p>
    </div>
  )
}

export default Page404;
