import React, { createContext, useState, useEffect, useContext } from 'react';
import SocketService from 'services/SocketService';
import { authContext } from './AuthProvider';

export const SocketContext = createContext(null);

function SocketProvider({ children }) {
    const [socket, setSocket] = useState(null);
    const { user } = useContext(authContext);

    useEffect(() => {
        if (socket && socket?.socket.listeners('connect')?.length === 0) {
            socket.on('connect', () => console.log('Connected'));
        }
        else if (user && !socket) {
            setSocket(new SocketService());
        }

        return function cleanup() {
            if (socket) {
                socket.disconnect();
            }
        }
    }, [socket, user]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    )
}

export default SocketProvider;