import './sideMenu.css';
import React, { Component } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';


type Props = {
  children: React.ReactNode,
  position: 'left' | 'right'
};

type State = {
  open: boolean,
};

class SideMenu extends Component<Props, State> {
  containerDivRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      open: true,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidMount() {
    this.setState({ open: false });
  }

  toggleOpen() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const btnPosition = this.props.position === 'right' ? 'left' : 'right';
    const width = this.containerDivRef.current?.offsetWidth ?? 0;

    const children = React.Children.map(this.props.children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { onClick: this.toggleOpen });
      }
      return child;
    });

    return (
      <div
        ref={this.containerDivRef}
        className='sidemenu-container'
        style={{
          [this.props.position]: this.state.open ? 0 : -width
        }}
      >
        <div
          className='sidemenu-btn-open'
          style={{
            [btnPosition]: -30
          }}
          onClick={this.toggleOpen}
        >
          <AiOutlineMenu size={30} />
        </div>
        <div className="sidemenu-content">
          {children}
        </div>
      </div>
    );
  }
}

export default SideMenu;
