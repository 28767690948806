import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

type Props = {
  open: boolean,
  children: React.ReactNode,
  duration?: number,
  alertType?: 'error' | 'success' | 'info' | 'warning'
  onClose?: () => void,
}

function ToastAlert({ open, children, duration = 5000, alertType = 'success', onClose }: Props) {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={duration}
      onClose={onClose}
    >
      <Alert elevation={6} variant='filled' severity={alertType} onClose={onClose}>
        {children}
      </Alert>
    </Snackbar>
  );
}

export default ToastAlert;