import React, { ChangeEvent, useState, useContext } from 'react';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import UsernameTitle from 'components/UsernameTitle';
import MediasViewer from './MediasViewer';
import { createPost } from 'API/app/post';
import Loading from 'components/LoadingIndicator';
import { popupRef } from 'components/Popup';
import { authContext } from 'components/Providers/AuthProvider';

type Props = {
  medias: any,
  onAddImage: Function,
  onRemoveImage: Function,
}

function PostForm({ medias, onAddImage, onRemoveImage }: Props) {
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [published, setPublished] = useState(false);

  const { user } = useContext(authContext);

  const handleDescritionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  }

  const handleSubmit = () => {
    setLoading(true);
    setPublished(false);
    createPost(description, medias)
      .then(data => {
        if (data.success) {
          setPublished(true);
          setDescription('')
        }
        else {
          setLoading(false);
          popupRef.current?.open('Publication non envoyée !', 'Une erreur est survenue lors de la publication. \n Veuillez réessayer !');
        }
      })
      .catch(error => {
        setLoading(false);
        popupRef.current?.open('Publication non envoyée !', 'Une erreur est survenue lors de la publication. \n Veuillez réessayer !');

        console.error(error);
      })
  }

  return (
    <div className='post-form-container'>
      {
        loading && (
          <div className="post-form-loading">
            <Loading height={150} width={150} loadingCompleted={published} />
            <p>
              {published ? 'Votre publication a été enregistré !' : 'Publication en cours...'}
            </p>
          </div>
        )
      }
      <div className='post-form-images-container'>
        <MediasViewer medias={medias} onAddImage={onAddImage} onRemoveImage={onRemoveImage} />
      </div>
      <div className='post-form-options-container' style={{ display: loading ? 'none' : 'flex' }}>
        <div>
          <UsernameTitle user={user} />
          <TextInput
            name='description'
            type='text'
            label='Ecrivez une légande...'
            multiline
            rows={6}
            onChange={handleDescritionChange}
          />
        </div>
        <Button onClick={handleSubmit}>Publier</Button>
      </div>
    </div>
  );
}

export default PostForm;