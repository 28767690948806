import './emojiPicker.css';
import React, { useState } from 'react';
import Emoji from 'emoji-picker-react';
import Button from 'components/Button';
import { GrEmoji } from 'react-icons/gr';

type Props = {
  onEmojiClick: any
}

function EmojiPicker({ onEmojiClick }: Props) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(open => !open);
  }

  const handleEmojiClick = (event: any, emojiObject: any) => {
    toggleOpen();
    onEmojiClick(event, emojiObject)
  }

  return (
    <div>
      <div style={{ position: 'relative' }}>
        {open && (
          <Emoji
            pickerStyle={{
              position: "absolute",
              bottom: '0',
              left: '0',
              zIndex: '10'
            }}
            disableSearchBar
            disableSkinTonePicker
            native
            preload={false}
            onEmojiClick={handleEmojiClick}
          />
        )}
      </div>
      <Button color='secondary' isIconBtn onClick={toggleOpen}>
        <GrEmoji size={30} />
      </Button>
    </div>
  );
}

export default EmojiPicker;
