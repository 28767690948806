import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export function getConfig() {
    const userToken = cookies.get('userToken');

    return {
        baseURL: process.env.REACT_APP_SERVER_URL,
        withCredentials: true,
        headers: {
            'Accept': '*/*; charset=utf-8',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + userToken?.token
        },
        transformRequest: [
            (data, headers) => {
                if (data instanceof FormData) {
                    return data;
                }
                data = {
                    ...data,
                    userId: userToken?.userId,
                    refreshToken: userToken?.refreshToken
                };
                return JSON.stringify(data);
            },
        ],
    }
}

export function getMultipartConfig() {
    let config = getConfig();
    config.headers['Content-Type'] = 'multipart/form-data; boundary=jkjkkkyggy';

    const userToken = cookies.get('userToken');

    let formData = new FormData();
    formData.append('userId', userToken?.userId);
    formData.append('refreshToken', userToken?.refreshToken);

    return {
        config,
        formData,
    };
}