import React, { useState } from 'react';
import { login as sendLoginRequest, loginWithToken as loginToken } from 'API/app/auth'
import { Cookies } from 'react-cookie';
import { User } from 'types/User';

export const authContext = React.createContext();

function useAuth() {
    const [user, setUser] = useState();

    const login = (account, psw, cb) => {
        sendLoginRequest(account, psw)
            .then(data => {
                if (data.userId) {
                    setUser(Object.assign(new User(), data.user));
                }
                cb(data);
            })
            .catch(error => console.log(error));
    }

    const loginWithToken = () => {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        if (userToken?.userId) {
            loginToken()
                .then(data => {
                    if (data.userId) {
                        setUser(Object.assign(new User(), data.user));
                    }
                    else {
                        logout();
                    }
                })
                .catch(error => console.log(error));
        }
    }

    const logout = () => {
        const cookies = new Cookies();
        cookies.remove('userToken', { path: '/', domain: process.env.NODE_ENV === 'development' ? '' : 'admandev.fr' });

        window.location.reload();
    }

    return {
        user,
        setUser,
        login,
        loginWithToken,
        logout,
    };
}

function AuthProvider({ children }) {
    const auth = useAuth()

    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

export default AuthProvider;
