import axios from 'axios';
import { getConfig } from './axiosConfig';

const baseUrl = `${process.env.REACT_APP_SERVER_URL}/auth`;

export async function signup(name: string, email: string, psw: string, pswConfirm: string) {
    const response = await axios.post('/auth/signup', {
        name,
        email,
        psw,
        pswConfirm
    }, getConfig());

    return response.data;
}

export async function login(login: string, psw: string) {
    const response = await axios.post('/auth/login', { login, psw }, getConfig());
    return response.data;
}

export async function loginWithToken() {
    const response = await axios.post('/auth/loginwithtoken', {}, getConfig());
    return response.data;
}

export async function sendBackActivateLink(userId: string) {
    const response = await axios.get(`${baseUrl}/activate/sendback/${userId}`);
    return response.data;
}

export async function sendResetPasswordLink(login: string) {
    const response = await axios.post('/auth/reset', { login }, getConfig());
    return response.data;
}

export async function resetPassword(token: string, psw: string, pswConfirm: string) {
    const response = await axios.post(`/auth/reset/${token}`, { psw, pswConfirm }, getConfig());
    return response.data;
}

export async function changePassword(fields?: any) {
    const response = await axios.post('/auth/changepassword', fields, getConfig());
    return response.data;
}