import React from 'react';
import VideoImageThumbnail from 'react-video-thumbnail-image';
import { AiFillHeart } from 'react-icons/ai';
import { FaComment } from 'react-icons/fa';

function PostListItem({ post, onClick }) {
    if (!post?.medias?.length === 0) {
        return <></>;
    }

    const media = post.medias[0];
    const isImage = media.type.includes('image');

    const handleOnClick = () => {
        onClick(post)
    }

    return (
        <div className='ml-item' onClick={handleOnClick}>
            <div style={{
                backgroundImage: isImage ? `url(${media.url})` : 'inherit'
            }}>
                {
                    !isImage && (
                        <VideoImageThumbnail
                            videoUrl={media.url}
                            alt={media.name}
                        />
                    )
                }
                <div className="ml-item-overlay">
                    <div>
                        <AiFillHeart /><b>{post.likes?.length}</b>
                    </div>
                    <div>
                        <FaComment /><b>{post.comments?.length}</b>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostListItem
