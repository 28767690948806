import './loginPage.css';
import React, { useState, useContext, useEffect } from 'react';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';
import { useHistory, useLocation } from 'react-router-dom';
import { urlParamsToObj } from 'utils/utils';
import NewAccountInfo from './components/NewAccountInfo';
import PasswordLostForm from './components/PasswordLostForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import { authContext } from 'components/Providers/AuthProvider';

function LoginPage() {
    const [formName, setFormName] = useState('login');
    const [newAcInfo, setNewAcInfo] = useState({ msg: '', userId: '', error: false, showBtn: false });
    const [resetToken, setResetToken] = useState('');
    const location = useLocation();
    const history = useHistory();
    const { user, loginWithToken } = useContext(authContext);

    useEffect(() => {
        if (location.search) {
            const params = urlParamsToObj(location.search);

            if (params.activate === 'false') {
                switch (params.error) {
                    case 'expiredToken':
                        setNewAcInfo({
                            msg: "Votre lien d'activation n'est plus valide.",
                            userId: params.userId,
                            error: true,
                            showBtn: true
                        });
                        break;

                    default:
                        setNewAcInfo({
                            msg: "Une erreur inconnue empêche l'activation de votre compte.",
                            userId: params.userId,
                            error: true,
                            showBtn: true
                        });
                        break;
                }
            }
            else if (params.activate === 'true') {
                setNewAcInfo({
                    msg: "Votre compte a été activé. Vous pouvez vous connecter ;)",
                    userId: '',
                    error: false,
                    showBtn: false
                });
            }
        }
    }, []);

    useEffect(() => {
        if (location.search) {
            const params = urlParamsToObj(location.search);
            if (params.resetToken) {
                setResetToken(params.resetToken);
                setFormName('resetPsw');
            }
        }
    }, []);

    useEffect(() => {
        if (!location.search && user?._id) {
            const from = location.state?.from.pathname;
            const redirect = from ? from : '/';

            history.push(redirect);
            history.go(1);
        }
    }, [user]);

    useEffect(() => {
        if (!user?._id) {
            loginWithToken();
        }
    }, []);

    const handleSwitchLoginSignupForm = () => {
        if (formName === 'login') {
            setFormName('signup');
        }
        else {
            setFormName('login');
        }
    }

    const handleOnPasswordLost = () => {
        setFormName('pswLost');
    }

    const handleOnSignup = (email, userId) => {
        handleSwitchLoginSignupForm();
        setNewAcInfo({
            msg: `Un email vous a été envoyé à ${email} afin d'activer votre compte.`,
            userId,
            error: false,
            showBtn: true
        });
    }

    const handleResetRequestSent = () => {
        handleSwitchLoginSignupForm();
        setNewAcInfo({
            msg: "Un email de réinitialisation de mot de passe vous a été envoyé.",
            userId: '',
            error: false,
            showBtn: false
        });
    }

    const handlePasswordReseted = () => {
        handleSwitchLoginSignupForm();
        setNewAcInfo({
            msg: "Votre mot de passe a été changé avec succès.",
            userId: '',
            error: false,
            showBtn: false
        });
    }

    const getForm = () => {
        switch (formName) {
            case 'signup':
                return <SignupForm onSignup={handleOnSignup} />

            case 'pswLost':
                return <PasswordLostForm onResetRequestSent={handleResetRequestSent} />

            case 'resetPsw':
                return <ResetPasswordForm token={resetToken} onResetRequestSent={handlePasswordReseted} />

            default:
                return <LoginForm onPasswordLost={handleOnPasswordLost} />
        }
    }

    return (
        <div id='lp-container'>
            {
                window.innerWidth > 680 &&
                <div id='lp-left-part'>
                    <img src={`https://picsum.photos/${window.innerWidth / 2}/${window.innerHeight}`} alt="random visual" />
                </div>
            }
            <div id='lp-right-part'>
                {
                    newAcInfo.msg &&
                    <NewAccountInfo
                        msg={newAcInfo.msg}
                        userId={newAcInfo.userId}
                        error={newAcInfo.error}
                        showBtn={newAcInfo.showBtn}
                    />
                }
                <div className='box'>
                    <h1 className='instacloneTitle'>Instaclone</h1>
                    {getForm()}
                </div>
                <div className='box'>
                    <p id='lp-signup-login-p'>
                        {formName === 'login' ? "Vous n'avez pas de compte ? " : "Vous avez un compte ? "}
                        <span onClick={handleSwitchLoginSignupForm}>
                            {formName === 'login' ? "Inscrivez-vous" : "Connectez-vous"}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;
