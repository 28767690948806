import React, { ChangeEvent, FormEvent, useState } from 'react';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { signup } from 'API/app/auth';

type Props = {
    onSignup: Function
}

function SignupForm({ onSignup }: Props) {
    const initialFields = {
        name: '',
        email: '',
        psw: '',
        pswConfirm: ''
    };

    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState([]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFields(fields => {
            return Object.assign({}, fields, { [e.target.name]: e.target.value });
        });
    }

    let canSubmit = fields.email && fields.name && fields.psw && fields.pswConfirm;

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (canSubmit) {
            canSubmit = '';
            signup(fields.name, fields.email, fields.psw, fields.pswConfirm)
                .then(data => {
                    if (data.success) {
                        setErrors([]);
                        onSignup(data.email, data.userId);
                    }
                    else if (data.errors) {
                        setErrors(data.errors);
                    }
                });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <p
                style={{
                    color: 'GrayText',
                    textAlign: 'center'
                }}
            >
                Inscrivez-vous sur Instaclone !
            </p>
            <TextInput
                name='name'
                type='text'
                label="Nom d'utilisateur"
                required={true}
                onChange={handleInputChange}
            />
            <TextInput
                name='email'
                type='email'
                label="Email"
                required={true}
                onChange={handleInputChange}
            />
            <TextInput
                name='psw'
                type='password'
                label="Mot de passe"
                required={true}
                onChange={handleInputChange}
            />
            <TextInput
                name='pswConfirm'
                type='password'
                label="Confirmez votre mot de passe"
                required={true}
                onChange={handleInputChange}
            />
            <Button color='primary' disabled={!canSubmit}>Suivant</Button>
            {
                errors?.length > 0 && <p className='error-text'>{errors.join('\n')}</p>
            }
        </form>
    )
}

export default SignupForm;
