import React, { ChangeEvent, FormEvent, useState } from 'react';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { resetPassword } from 'API/app/auth';

type Props = {
    token: string,
    onResetRequestSent: Function
}

function ResetPasswordForm({ onResetRequestSent, token }: Props) {
    const intitalFields = {
        psw: '',
        pswConfirm: ''
    };

    const [fields, setFields] = useState(intitalFields);
    const [errors, setErrors] = useState([]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFields(fields => {
            return Object.assign({}, fields, { [e.target.name]: e.target.value });
        });
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (fields) {
            resetPassword(token, fields.psw, fields.pswConfirm)
                .then(data => {
                    if (data.success) {
                        onResetRequestSent();
                    }
                    else if (data.errors?.length > 0) {
                        setErrors(data.errors);
                    }
                });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <TextInput
                name='psw'
                type='password'
                label="Nouveau mot de passe"
                required={true}
                onChange={handleInputChange}
            />
            <TextInput
                name='pswConfirm'
                type='password'
                label="Confirmez le mot de passe"
                required={true}
                onChange={handleInputChange}
            />
            <Button color='primary'>Changer mon mot de passe</Button>
            {
                errors?.length > 0 && <p className='error-text'>{errors.join('\n')}</p>
            }
        </form>
    )
}

export default ResetPasswordForm;
