import React from 'react';
import { CircularProgress } from '@material-ui/core'

type Props = {
  isLoading: boolean,
  yOffset?: number,
}

function ListLoadingView({ isLoading, yOffset = 0 }: Props) {
  return (
    <div style={{
      position: 'fixed',
      top: (isLoading ? 60 : -25) + yOffset,
      right: '50%',
      transform: 'translateX(-50%)',
      transition: 'top 500ms',
      zIndex: 1
    }}>
      <CircularProgress color='primary' />
    </div>
  );
}

export default ListLoadingView;
