import React, { Component, CSSProperties, RefObject } from 'react';
import Button from 'components/Button';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

interface IProps {
    ref?: RefObject<Popup>
}

interface IState {
    open: boolean,
    title: String,
    message: string,
    onConfirm?: Function
}

export const popupRef = React.createRef<Popup>();

export class Popup extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            open: false,
            title: '',
            message: ''
        };

        this.handleOnConfirm = this.handleOnConfirm.bind(this);
        this.close = this.close.bind(this);
    }

    open(title: string, message: string, onConfirm?: Function) {
        this.setState({
            open: true,
            title,
            message,
            onConfirm
        });
    }

    close() {
        this.setState({
            open: false
        });
    }

    handleOnConfirm() {
        if (this.state.onConfirm) {
            this.close();
            this.state.onConfirm();
        }
    }

    render() {
        const btnStyle: CSSProperties = {
            padding: '10px',
            margin: 0,
            borderTop: '1px solid var(--bordercolor)',
            textAlign: 'center',
        }

        return (
            <Dialog
                open={this.state.open}
                onClose={this.close}
            >
                <DialogTitle>{this.state.title}</DialogTitle>
                <DialogContent>{this.state.message}</DialogContent>
                <br />
                {
                    this.state.onConfirm && (
                        <p
                            className='text-btn'
                            style={{
                                ...btnStyle,
                                color: 'red',
                            }}
                            onClick={this.handleOnConfirm}
                        >
                            Confirmer
                        </p>
                    )
                }
                <p
                    className='text-btn'
                    style={{
                        ...btnStyle,
                        fontWeight: 'normal',
                        color: 'black',
                    }}
                    onClick={this.close}
                >
                    {this.state.onConfirm ? 'Annuler' : 'OK'}
                </p>
            </Dialog>
        )
    }
}

export default Popup;