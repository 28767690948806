import React, { useContext } from 'react';
import ProfilePicture from 'components/ProfilePicture';
import { Comment } from 'types/Comment';
import { authContext } from 'components/Providers/AuthProvider';
import ContextMenu from 'components/ContextMenu';
import MenuItem from 'components/ContextMenu/components/MenuItem';
import { FiMoreHorizontal } from 'react-icons/fi';
import { AiTwotoneDelete } from 'react-icons/ai';
import { deleteComment } from 'API/app/post';

type Props = {
  comment: Comment,
  showAvatar?: boolean,
  onDelete?: Function
}

function CommentItem({ comment, showAvatar, onDelete }: Props) {
  const auth = useContext(authContext);

  const handleDeleteComment = () => {
    deleteComment(comment?._id)
      .then(data => {
        if (data.success && onDelete) {
          onDelete(data.commentId);
        }
      })
      .catch(err => console.error(err));
  }

  return (
    <div className='ci-item'>
      {showAvatar && <ProfilePicture src={comment.user?.profilePicture} />}
      <p><b>{comment.user?.name}</b> {comment.text}</p>
      {auth.user?._id === comment.user?._id && comment._id && (
        <ContextMenu
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end'
          }}
          btnElement={<FiMoreHorizontal size={20} />}
        >
          <MenuItem
            icon={<AiTwotoneDelete size={20} />}
            text='Supprimer'
            color='red'
            onClick={handleDeleteComment}
          />
        </ContextMenu>
      )}
    </div>
  );
}

export default CommentItem;
