import React from 'react';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { AiOutlineClose } from 'react-icons/ai';

type Props = {
  ref?: React.RefObject<ScreenModal>,
  children: JSX.Element,
  showCloseBtn?: boolean
}

type States = {
  open: boolean,
}

class ScreenModal extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({ open: true });
  }

  close() {
    this.setState({ open: false });
  }

  render() {
    const { showCloseBtn = true } = this.props;

    return (
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'auto'
        }}
        open={this.state.open}
        onClose={this.close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.open}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            outline: 'none',
            padding: '10px'
          }}>
            {
              showCloseBtn && (
                <div
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    cursor: 'pointer',
                    zIndex: 3
                  }}
                  onClick={this.close}
                >
                  <AiOutlineClose size={25} />
                </div>
              )
            }
            {this.props.children}
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default ScreenModal;