import './footer.css';
import React from 'react';
import EmojiPicker from 'components/EmojiPicker';

function Footer({ onSubmit }) {
  const inputRef = React.createRef();

  const handleEmojiClick = (e, emojiObject) => {
    const myField = inputRef.current;
    if (myField.selectionStart || myField.selectionStart === '0') {
      var startPos = myField.selectionStart;
      var endPos = myField.selectionEnd;
      myField.value = myField.value.substring(0, startPos)
        + emojiObject.emoji
        + myField.value.substring(endPos, myField.value.length);
    } else {
      myField.value += emojiObject.emoji;
    }

    inputRef.current.focus();
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    const msg = inputRef.current?.value;
    inputRef.current.value = '';
    inputRef.current.focus();

    onSubmit(msg);
  }

  return (
    <div className='tchat-footer-container'>
      <EmojiPicker onEmojiClick={handleEmojiClick} />
      <input
        className='text-input'
        ref={inputRef}
        type='text'
        placeholder='Votre message...'
        onKeyDown={handleKeyDown}
      />
      <div className='text-btn' onClick={handleSubmit}>Envoyer</div>
    </div>
  );
}

export default Footer;