import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { User } from 'types/User';

type Props = {
  item: User,
  onRemove: (checked: boolean, user: User) => void,
}

function RecipientItem({ item, onRemove }: Props) {
  const handleOnRemove = () => {
    onRemove(false, item);
  }

  return (
    <div className='nmp-recipient-item'>
      <p>{item.name}</p>
      <AiOutlineClose onClick={handleOnRemove} />
    </div>
  );
}

export default RecipientItem;