import defaultProfilePicture from 'assets/images/default_profile_picture.png';
import React, { useState, useContext } from 'react';
import { AiTwotoneHeart } from 'react-icons/ai';
import { RiMoreLine } from 'react-icons/ri';
import PictureProfile from 'components/ProfilePicture';
import { TchatMessage } from 'types/TchatMessage';

type Props = {
  item: TchatMessage,
  localUserId?: string,
  showProfilePicture: boolean,
  showAuthor: boolean,
  onMessageLiked: (msgId?: string) => void,
  onRemoveMessage: (msgId?: string) => void
};

function MessageItem({ item, localUserId, showProfilePicture, showAuthor, onMessageLiked, onRemoveMessage }: Props) {
  const [showMenu, setShowMenu] = useState(false);

  const isOwnMessage = localUserId == item.author?._id;

  const toggleShowMenu = () => {
    setShowMenu(showMenu => !showMenu);
  }

  const closeMenu = () => {
    setShowMenu(false);
  }

  const toggleLikeMsg = () => {
    closeMenu();
    onMessageLiked(item._id);
  }

  const handleRemoveMessage = () => {
    closeMenu();
    onRemoveMessage(item._id);
  }

  return (
    <div className={`tchat-msg-item ${isOwnMessage && 'tchat-own-msg-item'}`} onMouseLeave={closeMenu}>
      {
        !isOwnMessage && (
          <img
            className='tchat-msg-profile-picture'
            src={item.author?.profilePicture ? item.author.profilePicture : defaultProfilePicture}
            style={{
              opacity: (showProfilePicture) ? 1 : 0
            }}
          />
        )
      }
      <div style={{ position: 'relative', marginLeft: 10 }}>
        {showAuthor && !isOwnMessage && <span className='tchat-msg-author'>{item.author?.name}</span>}
        <div className='tchat-msg-content'>
          {item.text}
        </div>
        {
          item.likes && item.likes.length > 0 && (
            <div className='tchat-msg-likes-container'>
              <AiTwotoneHeart size={16} color='red' stroke='black' strokeWidth={20} />
              {
                item.likes?.map((user, inx) => (
                  <PictureProfile
                    key={inx}
                    src={user.profilePicture}
                    size={16}
                    imgStyle={{
                      margin: 0,
                      marginLeft: 5
                    }}
                  />
                ))
              }
            </div>
          )
        }
      </div>
      <div className='tchat-msg-menu'>
        <RiMoreLine size={20} onClick={toggleShowMenu} />
        {
          showMenu && (
            <div className='tchat-msg-menu-content'>
              <p className='text-btn' onClick={toggleLikeMsg}>
                {
                  item.likes?.find(l => l._id === localUserId) ? "Je n'aime plus" : "J'aime"
                }
              </p>
              {isOwnMessage && <p className='text-btn' onClick={handleRemoveMessage}>Retirer</p>}
            </div>
          )
        }
      </div>
    </div>
  );
}

export default MessageItem;