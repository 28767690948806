import axios from "axios";
import { User } from "types/User";
import { getConfig, getMultipartConfig } from "./axiosConfig";

export async function getUser(username: string) {
  const response = await axios.post('/user', { username }, getConfig());
  const { user } = response.data;
  if (!user) {
    return response.data;
  }

  return Object.assign(new User(), user);
}

export async function getFamousUsers() {
  const response = await axios.post('/user/getfamous', {}, getConfig());
  return response.data;
}

export async function editUser(fields: any) {
  const { config, formData } = getMultipartConfig();

  for (let key in fields) {
    formData.append(key, fields[key]);
  }

  const response = await axios.post('/user/edit', formData, config);
  return response.data;
}

export async function searchUser(username: string, ids?: string[]) {
  const response = await axios.post('/user/search', { value: username, ids }, getConfig());
  return response.data;
}

export async function subscribe(userNameToSubscribe?: string) {
  const response = await axios.post('/user/subscribe', { userNameToSubscribe }, getConfig());
  return response.data;
}

export async function getFeed(perPage: number, page: number, date?: string, dateOption?: string) {
  const response = await axios.post('/user/feed', { perPage, page, date, dateOption }, getConfig());
  return response.data;
}