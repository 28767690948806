import './messagingPage.css';
import React, { useContext, useState, useEffect } from 'react';
import Button from 'components/Button';
import { FiEdit, FiSend } from 'react-icons/fi'
import { authContext } from 'components/Providers/AuthProvider';
import Tchat from './components/Tchat';
import { addUsersToGroup, createGroup, deleteGroup, editGroupSettings, getGroups, getOneGroup, leaveGroup } from 'API/app/tchat';
import { SocketContext } from 'components/Providers/SocketProvider';
import UsersSelectorPopup from './components/UsersSelectorPopup';
import GroupItem from './components/GroupItem';
import SideMenu from 'components/SideMenu';
import { useMediaQuery } from 'react-responsive';
import GroupDetails from './components/GroupDetails';
import ToastAlert from 'components/ToastAlert';
import { useLocation } from 'react-router';

function MessagingPage() {
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState();
  const [showGroupDetails, setShowGroupDetails] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const isDesktop = useMediaQuery({ query: '(min-width: 760px)' });
  const { user } = useContext(authContext);
  const socket = useContext(SocketContext);
  const location = useLocation();
  const usPopupRef = React.createRef();

  let group = groups.find(g => g._id === selectedGroupId);

  useEffect(() => {
    getGroups()
      .then(data => {
        if (data.success) {
          setGroups(data.groups);
          selectGroupOnMount(data.groups);
        }
        else if (data.errors) {
          console.error(data.errors);
        }
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    socket.on('tchatGroupSettingsChange', ({ group }) => updateGroup(group._id, group));
    socket.on('groupDeleted', ({ groupId }) => deleteGroupFromState(groupId));
    socket.on('userLeftGroup', ({ groupId, userId }) => editMembersOfGroup(groupId, [userId], false));
    socket.on('usersJoinGroup', ({ groupId, users }) => editMembersOfGroup(groupId, users, true));

    return function cleanup() {
      socket.removeAllListeners('tchatGroupSettingsChange');
      socket.removeAllListeners('groupDeleted');
      socket.removeAllListeners('userLeftGroup');
      socket.removeAllListeners('usersJoinGroup');
    }
  }, [groups, selectedGroupId]);

  useEffect(() => {
    if (selectedGroupId) {
      getOneGroup(selectedGroupId)
        .then(data => {
          if (data.success) {
            updateGroup(data.group);
          }
        })
        .catch(err => console.error(err));

      if (showGroupDetails) {
        toggleShowGroupDetails();
      }
    }
  }, [selectedGroupId]);

  const selectGroupOnMount = (groups) => {
    const selectGroup = location.state?.selectGroup;
    if (selectGroup) {
      const groupToSelect = groups.find(g =>
        g.members.length === 2
        && g.members.find(m => m._id === selectGroup.with) != undefined
      );

      if (groupToSelect) {
        setSelectedGroupId(groupToSelect._id)
      }
      else {
        createTchatGroup([selectGroup.with]);
      }
    }
  }

  const closeAlert = () => {
    setShowAlert(false);
  }

  const handleAddUserToGroup = () => {
    usPopupRef.current?.open(includeUsersToGroup, group.members.map(u => u._id))
  }

  const includeUsersToGroup = usersIds => {
    addUsersToGroup(socket.socket.id, group._id, usersIds)
      .catch(err => console.error(err));
  }

  const handleNewMessage = () => {
    usPopupRef.current?.open(createTchatGroup);
  }

  const handleAddRemoveMessage = (groupId, msg, added) => {
    const groupInx = groups.findIndex(g => g._id === groupId);

    if (groupInx !== -1) {
      const copyGroups = [...groups];
      if (added) {
        copyGroups[groupInx].messages?.push(msg);
      }
      else {
        copyGroups[groupInx].messages = copyGroups[groupInx].messages.filter(m => m._id !== msg._id);
      }

      setGroups(copyGroups);
    }
  }

  const handleLikeMessage = (groupId, msgId, userId, isLiked) => {
    const groupInx = groups.findIndex(g => g._id === groupId);

    if (groupInx !== -1) {
      const copyGroups = [...groups];
      const msgInx = copyGroups[groupInx].messages?.findIndex(m => m._id === msgId);
      if (isLiked) {
        const tUser = copyGroups[groupInx].members.find(m => m._id === userId);
        copyGroups[groupInx].messages[msgInx].likes.push(tUser);
      }
      else {
        copyGroups[groupInx].messages[msgInx].likes = copyGroups[groupInx].messages[msgInx].likes.filter(l => l._id !== userId);
      }
      setGroups(copyGroups);
    }
  }

  const updateGroup = (groupId, newGroup) => {
    const groupInx = groups.findIndex(g => g._id === groupId);

    if (groupInx !== -1) {
      const copyGroups = [...groups];
      copyGroups[groupInx] = newGroup;

      if (newGroup._id = group._id) {
        group = newGroup;
      }

      setGroups(copyGroups);
    }
  }

  const editMembersOfGroup = (groupId, users, add) => {
    const groupInx = groups.findIndex(g => g._id === groupId);

    if (groupInx !== -1) {
      const copyGroups = [...groups];

      if (add) {
        copyGroups[groupInx].members = [
          ...copyGroups[groupInx].members,
          ...users,
        ]
      }
      else {
        copyGroups[groupInx].members = copyGroups[groupInx].members.filter(m => !users.includes(m._id));
      }

      if (copyGroups[groupInx]._id == group?._id) {
        group = copyGroups[groupInx];
      }

      setGroups(copyGroups);
    }
  }

  const createTchatGroup = (membersIds) => {
    if (membersIds && membersIds?.length > 0) {
      createGroup(socket.socket.id, membersIds)
        .then(data => {
          if (data.success) {
            setGroups(groups => [...groups, data.group]);
            setSelectedGroupId(data.group._id);
          }
          else {
            console.error(data);
          }
        })
        .catch(err => console.error(err));
    }
  }

  const handleLeaveGroup = (groupId, userId) => {
    leaveGroup(socket.socket.id, groupId, userId)
      .catch(err => console.error(err));
  }

  const handleDeleteGroup = (groupId) => {
    deleteGroup(socket.socket.id, groupId)
      .catch(err => console.error(err));
  }

  const deleteGroupFromState = (groupId) => {
    if (groupId === selectedGroupId) {
      setShowGroupDetails(false);
      setSelectedGroupId(undefined);
    }

    setGroups(groups => groups.filter(g => g._id !== groupId));
  }

  const handleGroupSelected = (group) => {
    setSelectedGroupId(group._id);
  }

  const toggleShowGroupDetails = () => {
    setShowGroupDetails(showGroupDetails => !showGroupDetails);
  }

  const handleGroupSettingsChange = (gName) => {
    const settings = {
      name: gName,
    };

    editGroupSettings(socket.socket.id, group._id, settings)
      .then(data => {
        if (data.success) {
          setShowAlert(true);
        }
      })
      .catch(err => console.error(err));
  }

  const noTchatSelectedView = () => {
    return (
      <div className='mp-no-tchat-view'>

        <div className='mp-send-middle-logo'>
          <FiSend size={55} strokeWidth={1} />
        </div>
        <p className='mp-no-tchat-your-message-p'>Vos messages</p>
        <p className='mp-no-tchat-long-p'>Envoyez des photos et des messages privés à un(e) ami(e) ou à un groupe.</p>
        <Button fullWidth={false} onClick={handleNewMessage}>
          Envoyer un message
        </Button>
      </div>
    );
  }

  const groupsView = () => {
    return (
      <div className='mp-groups-container'>
        <div className='mp-group-header'>
          <p>{user.name}</p>
          <Button className='mp-group-new-msg-btn' isIconBtn onClick={handleNewMessage}>
            <FiEdit size={30} strokeWidth={1} />
          </Button>
        </div>
        <div className='mp-groups-items-container'>
          {
            groups.map((group, inx) => (
              <GroupItem
                group={group}
                localUsername={user.name}
                selected={selectedGroupId === group._id}
                key={inx}
                onClick={handleGroupSelected}
              />
            ))
          }
        </div>
      </div>
    );
  }

  return (
    <div className='page-container'>
      <ToastAlert open={showAlert} alertType='success' onClose={closeAlert}>
        Le groupe à été modifié avec succès.
      </ToastAlert>
      <div className='mp-container'>
        {
          !isDesktop ? (
            <SideMenu position='left'>
              {groupsView()}
            </SideMenu>
          ) : groupsView()
        }
        <UsersSelectorPopup
          ref={usPopupRef}
          user={user}
        />
        <div className='mp-tchat-container'>
          {
            selectedGroupId ? (
              !showGroupDetails ? (
                <Tchat
                  group={group}
                  socket={socket}
                  addRemoveMessage={handleAddRemoveMessage}
                  onShowDetails={toggleShowGroupDetails}
                  onMsgLiked={handleLikeMessage}
                />
              ) : (
                <GroupDetails
                  group={group}
                  onFinish={toggleShowGroupDetails}
                  onSettingsChange={handleGroupSettingsChange}
                  onDeleteGroup={handleDeleteGroup}
                  onLeaveGroup={handleLeaveGroup}
                  onAddUserToGroup={handleAddUserToGroup}
                />
              )

            ) : noTchatSelectedView()
          }
        </div>
      </div>
    </div>
  );
}

export default MessagingPage;
