import React, { useContext } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Header from 'components/Header';
import { authContext } from 'components/Providers/AuthProvider';
import Home from 'scenes/Home';
import LoginPage from 'scenes/LoginPage';
import PrivateRoute from "./PrivateRoute";
import Upload, { uploadRef } from 'scenes/UploadPage';
import ProfilePage from 'scenes/ProfilePage';
import ExplorePage from 'scenes/ExplorePage';
import SettingsPage from 'scenes/SettingsPage';
import MessagingPage from 'scenes/MessagingPage';

function Navigation() {
    const { user } = useContext(authContext)
    return (
        <Router>
            {
                user?._id && (
                    <>
                        <Header />
                        <Upload ref={uploadRef} />
                    </>
                )
            }
            <Switch>
                <PrivateRoute exact path='/'>
                    <Home />
                </PrivateRoute>
                <Route exact path='/login'>
                    <LoginPage />
                </Route>
                <PrivateRoute exact path='/messaging'>
                    <MessagingPage />
                </PrivateRoute>
                <PrivateRoute exact path='/explore'>
                    <ExplorePage />
                </PrivateRoute>
                <PrivateRoute exact path='/account/settings'>
                    <SettingsPage />
                </PrivateRoute>
                <PrivateRoute path='/*'>
                    <ProfilePage />
                </PrivateRoute>
            </Switch>
        </Router>
    )
}

export default Navigation;
