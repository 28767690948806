import React, { useState, useEffect, useContext } from 'react';
import { getFamousUsers, subscribe } from 'API/app/user';
import Button from 'components/Button';
import { authContext } from 'components/Providers/AuthProvider';
import UsernameTitle from 'components/UsernameTitle';
import { User } from 'types/User';

function EmptyItem() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0'
      }}
    >
      <div
        style={{
          backgroundColor: '#dddddd',
          width: 40,
          height: 40,
          marginRight: 10,
          borderRadius: '50%'
        }}
      />
      <div
        style={{
          backgroundColor: '#dddddd',
          width: 200,
          height: 10
        }}
      />
    </div>
  )
}

function Suggestions() {
  const [suggestUsers, setSuggestUsers] = useState<User[]>([]);
  const { user, setUser } = useContext(authContext);

  useEffect(() => {
    getFamousUsers()
      .then(data => {
        if (data.success) {
          setSuggestUsers(data.users);
        }
        else {
          console.error(data);
        }
      })
      .catch(err => console.error(err))
  }, []);

  const handleSubscribe = (item: User, userInx: number) => {
    subscribe(item.name)
      .then(data => {
        if (data.success && item._id) {
          if (data.subscribed) {
            item.subscrabers?.push(user._id);

            const userSubscriptions = user.subscriptions ?? [];
            setUser((user: User) => ({
              ...user,
              subscriptions: [...userSubscriptions, item._id]
            }));
          }
          else {
            item.subscrabers = item.subscrabers?.filter(s => s !== user._id);
            setUser((user: User) => ({
              ...user,
              subscriptions: user.subscriptions?.filter(s => s !== item._id)
            }));
          }

          const copySuggestUsers = [...suggestUsers];
          copySuggestUsers[userInx] = item;
          setSuggestUsers(copySuggestUsers);
        }
      })
      .catch(err => console.error(err))
  }

  return (
    <div
      style={{
        marginTop: 30,
      }}
    >
      <b>Suggestions pour vous</b>
      <div
        style={{
          backgroundColor: 'var(--backcolor)',
          marginTop: 10,
          padding: '10px 20px',
          border: '1px solid var(--bordercolor)'
        }}
      >
        {
          suggestUsers.length > 0 ? suggestUsers.map((item, inx) => (
            <div
              key={inx}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '20px 0'
              }}
            >
              <UsernameTitle
                user={item}
                imgSize={40}
                goToPageOnClick
                nameStyle={{
                  fontSize: 14,
                  fontWeight: 500
                }}
              />
              <Button
                fullWidth={false}
                color={item.subscrabers?.includes(user._id) ? 'secondary' : 'primary'}
                onClick={() => handleSubscribe(item, inx)}
              >
                {item.subscrabers?.includes(user._id) ? 'Se désabonner' : "S'abonner"}
              </Button>
            </div>
          )) : (
            <>
              <EmptyItem />
              <EmptyItem />
              <EmptyItem />
              <EmptyItem />
            </>
          )
        }
      </div>
    </div>
  );
}

export default Suggestions;