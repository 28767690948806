import { io } from "socket.io-client";
import { Cookies } from 'react-cookie';

const ENDPOINT = process.env.REACT_APP_SERVER_URL;

export default class SocketService {
    socket = { id: '' };

    constructor() {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        this.socket = io(ENDPOINT, {
            transports: ["websocket"],
            auth: {
                token: userToken?.token,
                userId: userToken?.userId,
            }
        });
    }

    connect = () => {
        this.socket.connect();
    }

    // disconnect from the server
    disconnect() {
        this.socket.disconnect();
    }

    on = (message, action) => {
        this.socket.on(message, action);
    }

    off = (event, listener) => {
        this.socket.off(event, listener);
    }

    removeAllListeners = (message) => {
        this.socket.removeAllListeners(message);
    }

    emit = (message, data) => {
        this.socket.emit(message, data);
    }
}