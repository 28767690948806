import './postViewer.css';
import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import UsernameTitle from 'components/UsernameTitle';
import Button from 'components/Button';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { FaRegComment } from 'react-icons/fa';
import { FiMoreHorizontal, FiSend } from 'react-icons/fi';
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';
import { AiTwotoneDelete } from 'react-icons/ai';
import TextInput from 'components/TextInput';
import "react-image-gallery/styles/css/image-gallery.css";
import MediaGallery from 'components/MediaGallery';
import { commentPost, deletePost, getPosts, togglePostLike, toggleSavePost } from 'API/app/post';
import { authContext } from 'components/Providers/AuthProvider';
import CommentItem from './CommentItem';
import { Comment } from 'types/Comment';
import ContextMenu from 'components/ContextMenu';
import MenuItem from 'components/ContextMenu/components/MenuItem';
import { popupRef } from 'components/Popup';
import { useHistory, useLocation } from 'react-router-dom';
import EmojiPicker from 'components/EmojiPicker';
import { comingSoonRef } from 'components/ComingSoon';

const iconSize = 32;

function PostViewer({ postId = undefined, item = undefined, verticalMode = false, style = undefined }) {
  const [post, setPost] = useState();
  const [saved, setSaved] = useState(false);
  const [btLikeSize, setBtLikeSize] = useState(iconSize);
  const auth = useContext(authContext);
  const history = useHistory();
  const location = useLocation();

  const commentInputRef = React.createRef();
  const commentContainerRef = React.createRef();

  const isDesktop = useMediaQuery({ query: '(min-width: 760px)' });

  const isOwnPost = auth.user?.posts.includes(postId);

  useEffect(() => {
    if (!item) {
      getPosts([postId])
        .then(data => {
          if (data.posts?.length > 0) {
            setPost(data.posts[0]);
          }
        })
        .catch(error => console.error(error));
    }
    else {
      setPost(item);
    }
  }, [postId, item]);

  useEffect(() => {
    setSaved(auth.user?.savedPosts?.includes(post?._id));

    commentContainerRef.current.scrollTo(0, 0);
  }, [post, auth, commentContainerRef]);

  const handleLikePost = () => {
    togglePostLike(post._id)
      .then(data => {
        if (data.success) {
          if (data.isLiked) {
            setPost(post => ({
              ...post,
              likes: [...post.likes, data.userId]
            }));
          }
          else {
            setPost(post => ({
              ...post,
              likes: post.likes.filter(l => l !== auth.user?._id)
            }));
          }
          handleBtLikeAnim();
        }
      })
      .catch(error => console.error(error));
  }

  const handleBtLikeAnim = () => {
    setBtLikeSize(40);
    setTimeout(() => {
      setBtLikeSize(iconSize);
    }, 100);
  }

  const handleSavePost = () => {
    toggleSavePost(post._id)
      .then(data => {
        if (data.success) {
          setSaved(data.postSaved);
          if (data.postSaved) {
            auth.user.savedPosts.push(post._id);
          }
          else {
            auth.user.savedPosts = auth.user.savedPosts.filter(p => p !== post._id);
          }
        }
      })
      .catch(error => console.error(error));
  }

  const handleEmojiClick = (e, emojiObject) => {
    const myField = commentInputRef.current;
    if (myField.selectionStart || myField.selectionStart === '0') {
      var startPos = myField.selectionStart;
      var endPos = myField.selectionEnd;
      myField.value = myField.value.substring(0, startPos)
        + emojiObject.emoji
        + myField.value.substring(endPos, myField.value.length);
    } else {
      myField.value += emojiObject.emoji;
    }
    handleCommentFocus();
  }

  const handleCommentFocus = () => {
    commentInputRef.current.focus();
  }

  const handleCommentPost = () => {
    const comment = commentInputRef.current.value;
    if (comment?.length > 0) {
      commentPost(post?._id, comment)
        .then(data => {
          if (data.success) {
            commentInputRef.current.value = '';
            setPost(post => ({
              ...post,
              comments: [data.comment, ...post.comments]
            }));
          }
        })
        .catch(error => console.error(error));
    }
  }

  const handleDeleteComment = (commentId) => {
    setPost(post => ({
      ...post,
      comments: post.comments.filter(c => c._id !== commentId)
    }));
  }

  const handleDeletePost = () => {
    const execDelete = () => {
      deletePost(post._id)
        .then(data => {
          const userProfiePath = '/' + auth.user?.name;
          if (location.pathname === userProfiePath) {
            window.location.reload();
          }
          else {
            history.push(userProfiePath);
            history.go(1);
          }
        })
        .catch(error => console.error(error));
    }

    popupRef.current.open('Supprimer la publication ?', 'Souhaitez-vous vraiment supprimer cette publication ?', execDelete);
  }

  const getComments = (showAvatar) => {
    return (
      <div className={'pv-comments-container'} ref={commentContainerRef}>
        {
          post?.description && <CommentItem comment={new Comment(post?.user, post?.description)} showAvatar={showAvatar} />
        }
        {
          post?.comments?.map((c, idx) => (
            <CommentItem comment={c} showAvatar={showAvatar} onDelete={handleDeleteComment} key={idx} />
          ))
        }
      </div>
    );
  }

  const isLiked = post?.likes?.find(l => l === auth.user?._id);

  return (
    <div className={`pv-container ${isDesktop && !verticalMode ? 'pv-vertical' : ''}`} style={style}>
      {
        isOwnPost && (
          <ContextMenu
            style={{
              position: 'absolute',
              right: isDesktop ? 10 : 30,
              top: 10
            }}
            btnElement={<FiMoreHorizontal size={20} />}
          >
            <MenuItem
              icon={<AiTwotoneDelete size={20} />}
              text='Supprimer'
              color='red'
              onClick={handleDeletePost}
            />
          </ContextMenu>
        )
      }
      <header>
        <UsernameTitle
          user={post?.user}
          goToPageOnClick
          imgSize={40}
          nameStyle={{
            fontWeight: 500,
            fontSize: 16,
            marginLeft: 10
          }}
        />
      </header>
      <div className='pv-media-container'>
        <MediaGallery medias={post?.medias} />
      </div>
      <div className='pv-info-container'>
        {
          isDesktop && getComments(true)
        }
        <div className="pv-buttons-container">
          <div className="pv-bt-like" style={{ width: iconSize, height: iconSize }}>
            <Button size={btLikeSize} fullWidth={false} isIconBtn onClick={handleLikePost}>
              {!isLiked ? <AiOutlineHeart size='100%' /> : <AiFillHeart size='100%' fill='red' />}
            </Button>
          </div>
          <Button size={iconSize} fullWidth={false} isIconBtn onClick={handleCommentFocus}>
            <FaRegComment size={26} strokeWidth={0} />
          </Button>
          <Button size={iconSize} fullWidth={false} isIconBtn onClick={comingSoonRef.current?.open}>
            <FiSend size='100%' strokeWidth={1.8} />
          </Button>
          <div className="pv-bookmark-btn">
            <Button size={iconSize} fullWidth={false} isIconBtn onClick={handleSavePost}>
              {saved ? <BsBookmarkFill size='100%' /> : <BsBookmark size='100%' />}
            </Button>
          </div>
        </div>

        <p className="pv-likes-count">
          {post?.likes?.length} J'aimes
        </p>

        {
          !isDesktop && getComments(false)
        }

        <div className='pv-comment-input-container'>
          <EmojiPicker onEmojiClick={handleEmojiClick} />
          <TextInput
            name='comment'
            type='text'
            label='Commenter...'
            variant='standard'
            inputRef={commentInputRef}
          />
          <p className='text-btn' onClick={handleCommentPost}>Envoyer</p>
        </div>
      </div>
    </div>
  );
}

export default PostViewer;
