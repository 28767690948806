import './mediaGallery.css';
import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { GrPrevious, GrNext } from 'react-icons/gr';

function MediaGallery({ medias }) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (medias) {
            const mediasMapped = medias.map(m => {
                let data = {
                    original: m.url,
                    thumbnail: m.url
                }

                if (m.type.includes('video')) {
                    data.renderItem = (item) => <video
                        width='100%'
                        height='100%'
                        src={item.original}
                        frameBorder={0}
                        controls
                        controlsList='nodownload'

                    />
                }

                return data
            })

            setItems(mediasMapped);
        }
    }, [medias])

    return (
        <div>
            <ImageGallery
                items={items}
                showThumbnails={false}
                useTranslate3D={false}
                showFullscreenButton={false}
                showPlayButton={false}
                showBullets
                disableKeyDown
                renderLeftNav={(onClick, disabled) => (
                    <button className='mg-nav-btn' disabled={disabled} onClick={onClick}>
                        <GrPrevious />
                    </button>
                )}
                renderRightNav={(onClick, disabled) => (
                    <button className='mg-nav-btn mg-nav-right' disabled={disabled} onClick={onClick}>
                        <GrNext />
                    </button>
                )}
            />
        </div>
    );
}

export default MediaGallery;
