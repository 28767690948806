import { ClickAwayListener } from '@material-ui/core';
import React, { useState } from 'react';

type Props = {
    active: boolean,
    activeIcon: JSX.Element,
    inactiveIcon?: JSX.Element,
    menu?: JSX.Element,
    onClick?: Function
}

function ButtonItem({ active, activeIcon, inactiveIcon, menu, onClick }: Props) {
    const [showMenu, setShowMenu] = useState(false);

    const toggleShowMenu = () => {
        if (menu) {
            setShowMenu(showMenu => !showMenu);
        }
    }

    const handleOnClick = () => {
        toggleShowMenu();
        if (onClick) {
            onClick();
        }
    }

    return (
        <div className='header-btn' onClick={handleOnClick}>
            {active ? activeIcon : inactiveIcon}
            {
                menu && showMenu &&
                <ClickAwayListener onClickAway={toggleShowMenu}>
                    <div className='menu-container'>
                        {menu}
                    </div>
                </ClickAwayListener>
            }
        </div>
    )
}

export default ButtonItem;
