import React, { useState, useContext, ChangeEvent, FormEvent } from 'react';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { authContext } from 'components/Providers/AuthProvider';
import { useHistory } from 'react-router-dom';

type Props = {
    onPasswordLost: () => void,
}

function LoginForm({ onPasswordLost }: Props) {
    const intitalFields = {
        login: '',
        psw: ''
    };

    const [fields, setFields] = useState(intitalFields);
    const [errors, setErrors] = useState([]);

    const { login } = useContext(authContext);
    const history = useHistory();

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFields(fields => {
            return Object.assign({}, fields, { [e.target.name]: e.target.value });
        });
    }

    const canSubmit: boolean = fields.login.length > 0 && fields.psw.length > 0;

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (canSubmit) {
            login(fields.login, fields.psw, (data: any) => {
                if (data.errors?.length > 0) {
                    setErrors(data.errors);
                }
                else if (data.userId) {
                    history.push('/');
                    history.go(1);
                }
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <TextInput
                name='login'
                type='text'
                label="Nom d'utilisateur ou email"
                required={true}
                onChange={handleInputChange}
            />
            <TextInput
                name='psw'
                type='password'
                label="Mot de passe"
                required={true}
                onChange={handleInputChange}
            />
            <Button color='primary' disabled={!canSubmit}>Connexion</Button>
            {
                errors?.length > 0 && <p className='error-text'>{errors.join('\n')}</p>
            }
            <p
                className='text-btn'
                style={{ margin: '10px 0', color: 'black', textAlign: 'center', fontWeight: 500 }}
                onClick={onPasswordLost}
            >
                Mot de passe oublié ?
            </p>
        </form>
    )
}

export default LoginForm;
