import axios from "axios";
import { getConfig, getMultipartConfig } from "./axiosConfig";

export async function createPost(description: string, medias: Array<File>) {
  const { config, formData } = getMultipartConfig();

  formData.append('description', description);
  for (let m of medias) {
    formData.append('medias', m);
  }


  const response = await axios.post('/post/create', formData, config);
  return response.data;
}

export async function deletePost(postId: string) {
  const response = await axios.post('/post/delete', { postId }, getConfig());
  return response.data;
}

export async function getPosts(postsIds?: Array<string>) {
  const response = await axios.post('/post', { postsIds }, getConfig());
  return response.data;
}

export async function explore(page?: number, perPage?: number, data?: string, dateOption?: string) {
  const response = await axios.post('/post/explore', { page, perPage, data, dateOption }, getConfig());
  return response.data;
}

export async function togglePostLike(postId: string) {
  const response = await axios.post('/post/like', { postId }, getConfig());
  return response.data;
}

export async function toggleSavePost(postId: string) {
  const response = await axios.post('/post/save', { postId }, getConfig());
  return response.data;
}

export async function commentPost(postId: string, text: string) {
  const response = await axios.post('/post/comment', { postId, text }, getConfig());
  return response.data;
}

export async function deleteComment(commentId?: string) {
  const response = await axios.post('/post/comment/delete', { commentId }, getConfig());
  return response.data;
}