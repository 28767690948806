import React, { useState, useMemo } from 'react';
import { AiTwotoneDelete } from 'react-icons/ai';
import { BsPlayFill, BsPlusSquare } from 'react-icons/bs';
import VideoImageThumbnail from 'react-video-thumbnail-image';

function MediasViewer({ medias, onAddImage, onRemoveImage }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleSelectMedia = (index) => {
    if (index >= 0 && index < medias?.length) {
      setSelectedIndex(index);
    }
  }

  const handleRemoveImage = () => {
    onRemoveImage(medias[selectedIndex]);
    setSelectedIndex(0);
  }

  const mediaElement = useMemo(() => {
    const media = medias[selectedIndex];
    const mediaUrl = media ? URL.createObjectURL(media) : null;
    const isImage = media.type.includes('image');

    if (isImage) {
      return (
        <div
          className='mv-selected-media'
          style={{
            backgroundImage: `url(${mediaUrl})`
          }}
        />
      );
    }
    else {
      return (
        <video
          className='mv-selected-media'
          src={mediaUrl}
          controls
          controlsList='nodownload'
        >
          {media.name}
        </video>
      );
    }
  }, [selectedIndex, medias]);

  return (
    <div className='mv-container'>
      <div className='mv-selected-container' >
        {mediaElement}
        <div className='mv-delete-img-btn' onClick={handleRemoveImage}>
          <AiTwotoneDelete size={25} color='grey' />
        </div>
      </div>
      <div className='mv-medias-list'>
        {
          medias.map((item, index) => {
            const url = URL.createObjectURL(item);
            const isImage = item.type.includes('image');
            let opacity = 0;
            if (index === selectedIndex) {
              opacity = 0.473
            }
            else if (!isImage) {
              opacity = 0.2
            }
            return (
              <div className='mv-thumbnail-btn' key={index} onClick={() => handleSelectMedia(index)}>
                <div className='mv-thumbnail-backdrop' style={{ opacity }} />
                {
                  isImage ?
                    <img
                      src={url}
                      alt={item.name}
                    /> :
                    <>
                      <VideoImageThumbnail
                        videoUrl={url}
                        alt={item.name}
                      />
                      <BsPlayFill size={30} className='mv-thumbnail-play-icon' />
                    </>
                }
              </div>
            )
          })
        }

        <div className='mv-thumbnail-btn' onClick={onAddImage}>
          <BsPlusSquare size={50} color='gray' />
        </div>
      </div>
    </div>
  )
}

export default MediasViewer
