import React, { useContext, useState, useEffect, ChangeEvent } from 'react'
import ProfilePicture from 'components/ProfilePicture';
import { authContext } from 'components/Providers/AuthProvider';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { editUser } from 'API/app/user';
import ToastAlert from 'components/ToastAlert';

function UserSettings() {
  const { user, setUser } = useContext(authContext);
  const [fields, setFields] = useState<any>({});
  const [pictureSrc, setPictureSrc] = useState<any>('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const pictureInputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    setPictureSrc(user.profilePicture);
    setFields((fields: any) => ({ ...fields, description: user.description }));
  }, [user]);

  const closeAlert = () => {
    setOpenAlert(false);
  }

  const handleOpenPictureInput = () => {
    pictureInputRef.current?.click();
  }

  const handlePictureChoosen = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setFields((fields: any) => ({
      ...fields,
      profilePicture: e.target.files?.item(0)
    }));

    var fr = new FileReader();
    fr.onload = function (e) {
      setPictureSrc(this?.result);
    };
    fr.readAsDataURL(e.target.files[0]);
  }

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFields((fields: any) => ({
      ...fields,
      [e.target.name]: e.target.value
    }));
  }

  const handleSubmit = () => {
    editUser(fields)
      .then(data => {
        if (data.success) {
          setUser(data.user);
          setOpenAlert(true);
        }
      })
      .catch(err => console.error(err));
  }

  return (
    <div className='sp-tab-content'>
      <ToastAlert open={openAlert} onClose={closeAlert}>
        Vos modifications ont été enregistrée.
      </ToastAlert>
      <div className='sp-row'>
        <div>
          <ProfilePicture src={pictureSrc} size={40} />
        </div>
        <div>
          <p className='sp-username'>{user.name}</p>
          <p className='text-btn' onClick={handleOpenPictureInput}>
            Changer la photo de profile
          </p>
          <input
            ref={pictureInputRef}
            type='file'
            style={{ display: 'none' }}
            accept='image/*'
            onChange={handlePictureChoosen}
          />
        </div>
      </div>

      <div className='sp-row'>
        <div>
          <h4>Nom d'utilisateur</h4>
        </div>
        <div>
          <TextInput
            name='name'
            type='text'
            label=''
            defaultValue={user.name}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className='sp-row'>
        <div>
          <h4>Bio</h4>
        </div>
        <div>
          <TextInput
            name='description'
            type='text'
            label=''
            defaultValue={user.description ?? ''}
            multiline
            rows={5}
            onChange={handleFieldChange}
          />
        </div>
      </div>

      <div className='sp-row'>
        <div />
        <div>
          <Button onClick={handleSubmit}>Envoyer</Button>
        </div>
      </div>

    </div>
  )
}

export default UserSettings
