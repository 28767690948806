import defaultProfilePicture from 'assets/images/default_profile_picture.png';
import React, { useContext } from 'react';
import { TchatGroup } from 'types/TchatGroup';
import { authContext } from 'components/Providers/AuthProvider';
import ProfilePicture from 'components/ProfilePicture';

type Props = {
  group: TchatGroup,
}

function GroupIcon({ group }: Props) {
  const { user } = useContext(authContext)
  const members = group.members?.filter(m => m.name !== user.name);

  const handleProfilePhotoError = (e: any) => {
    e.target.src = defaultProfilePicture;
  }

  if (members?.length === 1) {
    return (
      <div className='mp-group-picture-container'>
        <ProfilePicture src={members[0].profilePicture} width='auto' height='100%' />
      </div>
    );
  }
  else if (members && members?.length > 1) {
    const pp1 = members[0].profilePicture;
    const pp2 = members[1].profilePicture;
    return (
      <div className='mp-group-picture-container'>
        <img
          className='mp-group-picture-main'
          src={pp1 ? pp1 : defaultProfilePicture}
          alt={`Picture of ${members[0].name}`}
          onError={handleProfilePhotoError}
        />
        <img
          className='mp-group-picture-secondary'
          src={pp2 ? pp2 : defaultProfilePicture}
          alt={`Picture of ${members[1].name}`}
          onError={handleProfilePhotoError}
        />
      </div>
    );
  }
  else {
    return <></>;
  }
}

export default GroupIcon;