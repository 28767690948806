import './header.css';
import defaultProfilePicture from 'assets/images/default_profile_picture.png';
import React, { useContext } from 'react';
import { AiFillCompass, AiOutlineCompass, AiFillHeart, AiOutlineHeart, AiFillHome, AiOutlineHome, AiOutlinePlusSquare, AiFillPlusSquare } from 'react-icons/ai';
import { FiSend } from 'react-icons/fi';
import { RiSendPlaneFill } from 'react-icons/ri';
import ButtonItem from './ButtonItem';
import { useHistory, useLocation } from 'react-router-dom';
import ProfileMenu from './ProfileMenu';
import { authContext } from 'components/Providers/AuthProvider';
import { uploadRef } from 'scenes/UploadPage';
import Search from './Search';
import { comingSoonRef } from 'components/ComingSoon';
import zIndex from '@material-ui/core/styles/zIndex';

const iconConfig = {
    size: 26,
    strokeWidth: 1
}

function Header() {
    const { user } = useContext(authContext);
    const location = useLocation();
    const history = useHistory();

    const handleProfilePhotoError = (e: any) => {
        e.target.src = defaultProfilePicture;
    }

    const handleShowUploadPage = () => {
        uploadRef.current?.open();
    }

    const handleNavigate = (page: string) => {
        history.push(page);
        history.go(1);
    }

    return (
        <header style={{ zIndex: 3 }}>
            <div id="header-container">
                <div className="title-container">
                    <p className="instacloneTitle" style={{ cursor: 'pointer' }} onClick={() => handleNavigate('/')}>
                        Instaclone
                    </p>
                </div>
                <Search />
                <div className="actions-buttons-container">
                    <ButtonItem
                        active={location.pathname === '/'}
                        activeIcon={<AiFillHome {...iconConfig} />}
                        inactiveIcon={<AiOutlineHome {...iconConfig} />}
                        onClick={() => handleNavigate('/')}
                    />
                    <ButtonItem
                        active={location.pathname === '/messaging'}
                        activeIcon={<RiSendPlaneFill {...iconConfig} strokeWidth={0} />}
                        inactiveIcon={<FiSend {...iconConfig} />}
                        onClick={() => handleNavigate('/messaging')}
                    />
                    <ButtonItem
                        active={location.pathname === '/upload'}
                        activeIcon={<AiFillPlusSquare {...iconConfig} />}
                        inactiveIcon={<AiOutlinePlusSquare {...iconConfig} />}
                        onClick={handleShowUploadPage}
                    />
                    <ButtonItem
                        active={location.pathname === '/explore'}
                        activeIcon={<AiFillCompass {...iconConfig} />}
                        inactiveIcon={<AiOutlineCompass {...iconConfig} />}
                        onClick={() => handleNavigate('/explore')}
                    />
                    <ButtonItem
                        active={location.pathname === '/notification'}
                        activeIcon={<AiFillHeart {...iconConfig} />}
                        inactiveIcon={<AiOutlineHeart {...iconConfig} />}
                        onClick={comingSoonRef.current?.open}
                    />
                    <ButtonItem
                        active
                        activeIcon={
                            <img
                                className='header-profile-img'
                                src={user?.profilePicture ? user.profilePicture : defaultProfilePicture}
                                alt='profile avatar'
                                onError={handleProfilePhotoError}
                            />
                        }
                        menu={<ProfileMenu />}
                    />
                </div>
            </div>
        </header>
    )
}

export default Header;