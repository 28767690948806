import './contextMenu.css'
import React, { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';

type Props = {
  btnElement: JSX.Element,
  children: React.ReactNode
  style?: React.CSSProperties
}

function ContextMenu({ btnElement, children, style }: Props) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(open => !open);
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { toggleOpen });
    }
    return child;
  });

  return (
    <div
      style={{
        zIndex: open ? 1000 : 1,
        ...style,
      }}
      className='cm-container'
    >
      <div className='cm-btn' onClick={toggleOpen}>
        {btnElement}
      </div>
      <div className='cm-items-container'>
        {
          open && (
            <ClickAwayListener onClickAway={toggleOpen}>
              <div className="cm-items-content">
                {childrenWithProps}
              </div>
            </ClickAwayListener>
          )
        }

      </div>
    </div>
  );
}

export default ContextMenu;
